.dealerfinder__submit {
    @include media('>lg') {
        margin-left: $spacing-base * 0.85;
    }
}

.dealerfinder__content-wrapper {
    display: none;
    width: 100%;
    float: left;
    margin-top: 30px;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

#map_canvas {
    width: 100%;

    &.loaded {
        height: 500px;
    }
}

.dealerfinder {
    padding: $spacing-base * 0.625 0 $spacing-base * 1.25;
}

.find-dealer {
    display: none;
    position: relative;
}

.dealerfinder__block {
    position: absolute;
    background: rgba(255, 255, 255, .5);
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;

    &.active {
        display: none;
    }
}

.dealerfinder__title {
    font-size: 24px;
    margin-bottom: 0;
}

.dealerfinder__wrap {
    padding-bottom: $spacing-base * 1.25;

    input {
        border: 1px solid color(grey);
        box-shadow: none;

        &:hover,
        &:focus {
            border: 1px solid color(grey);
            box-shadow: none;
        }
    }
}

.dealerfinder__userlocation {
    color: black;
    font-size: 15px;
    line-height: 1;
    border-bottom: 1px solid color(black);
    margin-left: $spacing-base * 0.85;
    margin-top: $spacing-base * 1.25;
    position: relative;
    display: inline-block;

    &:before {
        content: '';
        margin-right: 10px;
        position: absolute;
        left: -24px;
        top: 0;
    }

    &:hover {
        text-decoration: none;
        color: color(black);
    }

}

.dealerfinder__error {
    padding: .5em 1em;
    color: color(black);
}

.dealerlist,
.dealerlist__details {
    list-style-type: none;
    margin: 0;
    padding: 0px;
}

.dealerlist {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    clear: both;
}

.dealerlist__item {
    @include grid-column(12);
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;

    @include media('>md') {
        @include grid-column(6);
    }

    @include media('>lg') {
        @include grid-column(4);
    }

    h4 {
        font-size: 17px;
        border-bottom: 1px solid color(light);
        margin-bottom: 10px;
        margin-top: $spacing-base * 0.85;
        padding-bottom: 5px;
        min-height: 50px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }
}

.dealerlist__contactperson {
    margin-bottom: $spacing-base * 0.85;
}

.dealerlist__details {
    position: relative;
    li > strong {
        width: $spacing-base * 0.85;
        display: inline-block;
    }
}

.dealerlist__contactbtn {
    margin-top: $spacing-base * 0.625;

    .btn {
        font-weight: bold;
        color: #fff;
        border-color: black;
        padding: 5px 10px;
        background-color: color(primary);

        &:hover {
            background-color: darken(color(primary), 5%);
        }
    }
}

.dealerlist__servicecenter {

    .icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 59px;
        //@include background-2x('/images/misc/servicecenter', 'webp', 54px, 59px, center center, no-repeat);
    }
}

.dealerfinder__tab {
    width: 50%;
    background: #F2F2F2;
    height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: color(black);
    float: left;

    vertical-align: middle;
    line-height: 2.666667;
    box-sizing: border-box;

    [class^="sprite-main-"] {
        margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
        margin-top: -5px;
    }

    &.active {
        border-top: 2px solid color(primary);
        background: color(white);
        font-weight: bold;
    }
}

.servicecenter__text {
    font-weight: bold;
    color: color(black);
    display: block;
    width: 55px;
    text-align: center;
    font-size: 13px;
    line-height: 1;
}

.dealerfinder__found {
    font-size: 19px;
    color: color(primary);
    font-weight: bold;
    margin: 0 0 15px;
    line-height: 1;
}

.pmc-selected {
    @media screen and (min-width: 971px) {
        opacity: 0.5;
        padding-bottom: 0 !important;
        transition: padding 0.25s ease;

        .content-wrapper {
            width: 99% !important;
        }
        h3 {
            margin: 5px 0;
            transition: margin 0.25s ease;
        }
        .pmc-selector {
            transform: scale(0.75);
            margin-top: -$spacing-base * 0.85;
            transition: all 0.25s ease;
        }
    }
}
