.content-page {
  position: relative;

  .blog {

    table {
      @include media('<md') {
        width: 100% !important;
      }
      @include media('>md') {
        margin: 5px;
      }
    }

    td {
      padding: 15px;
      box-sizing: border-box;
    }
  }

  .print-btn {
    display: flex;
    padding: 10px;

    @include media('>lg') {
      float: right;
    }
  }
    li{
    margin-bottom: .5em;
  }

  .collapse {
    // Fix for width children columns
    [class^=col-] {
      width: 100%;

      @include media('<md') {
        [class^=block-] {
          img {
            width: 100%;
          }
        }
      }

      @include media('<lg') {
        margin-left: 0;
        padding: 0 20px;
      }

      @include media('>md', '<=lg') {
        margin-left: 0;
        display: flex;
        justify-content: space-between;

        [class^=block-] {
          margin-right: 0;
        }
      }
    }
  }
}

.content-page__wrapper {
  @include grid-column(12);
  margin-top: 3rem;
  margin-bottom: 3rem;
}


.content-page,
.cke_contents {
  img, figure {
    max-width: 100%;

    &.alignleft,
    &.align-left,
    [data-align="left"]{
      float: left;
      margin-right: 10px;
    }

    &.alignright,
    &.align-right,
    [data-align="right"]{
      float: right;
      margin-left: 10px;
    }

    &.aligncenter,
    &.align-center,
    [data-align="center"]{
      display: block;
      margin: 0 auto;
    }
  }
}
