/**
  Most of this CSS is also included in the critical CSS for the Splash Page
  Mutations must be made manually
 */
.splash-pmc-list {
  @include list-clean;

  .is-general {
    margin-top: 1em;

    a {
      font-weight: normal;
    }

    ~ .is-general {
      margin-top: 0;
    }
  }
}

.splash-pmc-list__item {
  margin-left: 0;
}

.splash-pmc-list__link {
  @extend .separator;
  font-weight: bold;
  line-height: 1.5;
  display: block;
  border-bottom: 1px solid rgba(255,255,255,.25);
  text-decoration: none;
  margin-left: 0 !important;
  padding-left: $spacing-base !important;
  padding-top: .5em;
  padding-bottom: .5em;

  @include media('>md') {
    font-size: 1.25em;
    padding-top: .2em;
    padding-bottom: .2em;
  }

  &:before {
    background: color(white) !important;
    position: absolute !important;
    top: 8px !important;
  }

  &:hover {
    color: color(primary);
    border-bottom-color: white;
  }

  &:hover:before {
    background: color(primary) !important;
  }

  &:after {
    content: '\203A';
    display: inline-block;
    padding-left: $spacing-small;
    position: absolute;
    right: 0;
  }
}
