.dealer-finder-selector {
  display: inline-block;
  float: right;
  text-align: right;
  min-width: 35%;

  @include media('<md'){
    width: 100%;
  }

  * {
    display: inline-block;
  }
}

.dealer-finder-selector__label {
  font-size: $font-size-small;
  display: none;

  @include media('>lg') {
    display: inline-block;
  }
}

.dealer-finder-selector__form {
  width: 100%;
}

.dealer-finder-selector__select {
  max-width: 14em;
  border-color: color(white);
  color: color(text);
  margin: 0 $spacing-small;

  @include media ('<lg'){
    max-width: 13em;
    font-size: $font-size-small;
    margin: 0 5px 0 0;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.dealer-finder-selector__submit{
  @include media('<lg'){
    font-size: $font-size-small !important;
    padding: $spacing-tiny $spacing-tiny !important;
  }

  &:fist-child {
    @include btn-ghost;
  }
}
