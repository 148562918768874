/**
 * mixins.buttons
 */

/**
 * Base button mixin
 * 1. Use the shared input styles to match the button with inputs.
 * 2. Remove possible text decoration from the applied element, eg. links.
 */
@mixin btn {
  @include shared-input; /* [1] */
  -webkit-font-smoothing: antialiased;
  text-decoration: none; /* [2] */
  text-align: center;
  font-weight: bold;
  user-select: none;
  color: color(white);
  background-color: color(primary);
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.10);
  line-height: 1.56;

  #{$all-hovers} {
    color: color(white);
    background: darken(color(primary), 5%);

    border-radius: 4px;
  }

  &:after {
    content: '\203A';
    font-size: 22px;
    position: relative;
    padding-left: .3em;
    line-height: 1;
  }

  /**
   * 1. Shit needs to be immutable, disabled is disabled
   */
  &:disabled {
    color: color(white) !important; /* [1] */
    background-color: color(grey) !important; /* [1] */
    border-color: transparent !important; /* [1] */
    cursor: not-allowed;
  }

  svg {
    &:first-child {
      margin-right: $spacing-small;
    }

    &:last-child {
      margin-left: $spacing-small;
    }

    &:first-child:last-child {
      margin: 0;
    }
  }
}

/**
 * Cosmetic types
 */

@mixin btn-secondary {
  color: color(white);
  background-color: color(dark);
  border: 1px solid #414141;

  #{$all-hovers} {
    background-color: color(black);
    color: color(white);
  }
}

@mixin btn-tertiary {
  color: color(primary);
  background-color: color(white);
  border: 1px solid #D7D8D8;

  #{$all-hovers} {
    background-color: color(primary);
  }
}

/**
 * Ghost types
 */

/**
 * Shared, internal ghost mixin
 */

@mixin btn-ghost {
  @include btn-tertiary;
}

/**
 * Clean
 */

@mixin btn-clean {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  font: inherit;
  color: inherit;
  box-shadow: none;
  background-color: transparent;

  #{$all-hovers} {
    background-color: transparent;
    color: inherit;
  }

  &:after {
    content: none;
  }
}
