.header {
  height: 65px;
  background: color(dark);
  z-index: 11;
  top: 0;
  width: 100%;
  position: fixed;

  &--frontpage {
    position: relative;
  }

  @include media('>lg') {
    height: auto;
    position: relative;

    .toolbar-tray-open.toolbar-horizontal & {
      top: 79px;
    }
    .toolbar-tray-open.toolbar-horizontal.path-frontpage & {
      top: 0;
    }
  }

  &.header--frontpage {
    background: none;
  }
}

.header__container {
  position: relative;

  @include media('>md') {
    height: 171px;
  }

  .header--frontpage & {
    height: auto;
  }
}

.logo__wrapper {
  display: inline-block;
  position: relative;
  left: 5em;
  top: 18px;
  z-index: 5;

  @include media('>lg') {
    display: inline-block;
    top: 30px;
    left: 0;
  }
}

.header--frontpage {
  .logo__wrapper {
    left: 5px;
  }
}

.logo__image {
  width: 135px;
  height: 32px;

  @include media ('<=xm') {
    width: 105px;
  }

  @include media ('<=xs') {
    width: 50px;
  }

  @include media('>lg') {
    width: 280px;
    height: 60px;
  }
}

.top-nav {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 5;

  @include media('>md') {
    top: 0;

    .header--frontpage & {
      background: color(black);
      width: calc(50% + 50px);
      text-align: right;

      &:before {
        content: '';
        display: block;
        background: color(black);
        width: $region-nav-height;
        height: $region-nav-height;
        transform: skewX(18deg);
        position: absolute;
        left: -25px;
        bottom: 0;
        z-index: -1;
      }

      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        max-width: calc((100vw - 1200px)/2);
        background: color(black);
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
      }

      .toolbar-horizontal & {
        width: calc(50% + 40px);

        &:before{
          left: -23px;
        }
      }
      .toolbar-tray-open.toolbar-horizontal &{
        width: calc(50% + 30px);

        &:before{
          left: -20px;
        }
      }
    }
  }
}

.search-form__wrapper,
.nav-main {
  float: right;
  clear: both;
}

.nav-main {
  margin-top: 20px;
}

.search-form {
  float: left;
}

.find-dealer-link {
  float: right;

  @include media('<=lg') {
    display: none;
  }
}

.back-btn {
  position: absolute;
  bottom: 20px;
  z-index: 5;
  left: 0;
  @include media('>lg', '<=mw') {
    left: 20px;
  }
}
