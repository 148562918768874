.noUi-target {
  background: transparent;
  margin: 2.5em .5em 2.5em;
  width: calc(100% - 80px);
  display: inline-block;

  .js-range-slider .btn--yellow-circle {
    float: right;
    margin-top: 2.3em;
  }

  &.noUi-horizontal {
    height: 4px;
    border: none;
    border-bottom: 1px solid black;
  }

  .noUi-connects {
    border-radius: 0;
    background: #ddd;
    border: none;
    height: 4px;

    .noUi-connect {
      background: color(grey);
      border: none;
    }
  }
}

.noUi-horizontal .noUi-handle {
  top: -8px;
  background: color(primary);
  cursor: move;
  box-shadow: none;
  width: 14px;
  height: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;

  &:after, &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    background: none;
    border-color: rgba(244, 92, 0, 0);
    border-top-color: color(primary);
    border-width: 7px;
    margin-left: -7px;
  }

  &:focus {
    outline-color: transparent;
    outline-width: 0;
    background-color: color(primary);
    border-color: color(grey);
  }
}

.noUi-tooltip {
  border: none;
  background: none;
  top: 130%;
  font-size: $font-size-small;
  font-weight: 300;
}

