$region-nav-height: $spacing-base*2 + $line-height-base;

.region-nav {
  @include list-inline;
  display: inline-flex;
  position: relative;
  margin: 0;
  z-index: 4;
  background: color(dark);
  padding-right: $spacing-small;

  @include media('>=container') {
    margin-right: -1px;
    padding-right: 0;
  }

  &.region-nav--splash{
    background: transparent;
    padding-right: $spacing-base;
  }
}

.region-nav__item {
  position: relative;
}

.region-nav__item-link {
  display: inline-block;
  margin: 0;
  color: color(grey);
  font-size: $font-size-small;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
  padding: $spacing-small*1.5 1rem;

  .region-nav--splash & {
    color: color(white);
  }

  .region-nav__item:first-child & {
    padding-left: $spacing-small;
  }

  #{$all-hovers} {
    text-decoration: none;
    color: color(primary);
  }

  @include media('<container'){
    padding: $spacing-small*1.5 .5rem;
  }
}

.region-nav__item-link.is--active {
  background: color(black) !important;
}

.region-nav__separator {
  margin-left: $spacing-small;
  margin-right: $spacing-small;
  display: inline-block;
  width: 2em;
  text-align: center;
  top: 2px;
}

.region-nav__item-link-icon {
  line-height: 1;
  position: relative;
  bottom: -2px;
  margin-right: .5em;
}

.region-nav__item-link-chevron {
  display: inline-block;
  margin-left: .5em;
}

.select-language-wrap,
.region-picker__confirm {
  display: inline-block;
}

// Wishlist
.region-nav__wishlist {
  display: flex;

  @include media('<md') {
    width: 40px;
    height: 40px;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 1;
  }
}