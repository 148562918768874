.social-share{
  display: inline-flex;
}

.social-share__label {
  line-height: 25px;
  display: inline-block;
  margin-right: .5em;
}

.social-nav__list {
  @include list-inline;

  @include media('<=lg') {
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .footer-address__wrapper & {
      float: left;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .social-nav__list-item {
      margin-right: 10px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include media('>lg') {
    text-align: right;
    .footer-address__wrapper & {
      text-align: left;
      margin-top: 1em;
    }
  }

  .social-share & {
    display: inline-block;
  }
}

.social-nav__list-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  &.social--instagram {
    svg {
      transform: scale(1.3);
      fill: white;
    }
  }

  .footer-address__wrapper & {
    background-color: color(primary);
  }

  @include media('<=lg') {
    width: 35px;
    height: 35px;

    svg {
      width: 20px;
      height: 16px;
    }
  }
  @include media('>lg') {
    width: 25px;
    height: 25px;

    #{$all-hovers} {
      background: color(primary);
    }
  }
}
