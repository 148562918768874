.header-small {
  height: 65px;
  position: relative;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @include media('<md') {
    display: flex;
  }

  @include media('>lg') {
    height: auto;
  }
}

.header-small__container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;

  @include media('>md') {
    height: 171px;
    margin: 0 auto;
  }
}
