.region-picker__countrygroup .st0{
  fill: color(text);
  stroke: transparent;
  stroke-width: 10px;
}

.region-picker__countrygroup:hover .st0{
  fill: color(grey);
  cursor: pointer;
}

.region-picker__countrygroup--selected .st0,
.region-picker__countrygroup--selected:hover .st0{
  fill: color(primary);
}
