.product-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  color: color(text);
  text-decoration: none;
  transition: border ease 0.1s;
  height: 100%;

  .product-item__wrapper--related & {
    height: 315px;
  }
}

.product-item__wrapper {
  @include grid-column(12);
  position: relative;
  margin-bottom: $spacing-base * 0.85;
  border: 1px solid color(light);
  top: 0;
  transition: all ease 0.1s;
  background-color: color(white);
  display: flex;
  flex-direction: column;

  #{$all-hovers} {
    border: 1px solid color(primary);
    top: -5px;

    .product-item__title {
      color: color(primary);
    }
  }

  @include media('<md') {
    margin-left: $spacing-base * 0.85;
    float: none;
    display: inline-block;
  }

  @include media('>md', '<=lg') {
    @include grid-column(6);
  }

  @include media('>lg') {
    @include grid-column(4);
  }

  &.product-item__wrapper--upsell {
    @include media('>lg') {
      @include grid-column(3);
    }
  }

  &.product-item__wrapper--related {
    @include media('>lg') {
      @include grid-column(3);
    }
  }

  .wishlist & {
    margin: 0;
    width: 100%;
    border: none;

    &:hover {
      top: 0;
    }
  }
}

.product-item__img-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  overflow: hidden;

  img {
    //max-height: 100%;
    ////@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    ////  flex: 1 0 100%;
    ////}
    //@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //    width: auto;
    //    height: auto;
    //}
    //
    //@include media('<=md') {
    //    max-height: 150px;
    //}
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 200px;
    font-size: 14px;
  }

  @include media('>lg') {
    margin-top: 1rem;

    .product-item__wrapper--related & {
      margin-top: 0;
      height: 235px;
    }
  }
}

.product-item__label {
  position: relative;
  z-index: 1;
  left: $spacing-base * 0.85;
  top: 11px;
  font-weight: bold;
  margin-left: 0 !important;
  margin-top: 0 !important;
  font-size: $font-size-small;

  label {
    margin-top: 0;
  }
}

$label-radius: 3px;
.product-item__status {
  position: absolute;
  background: color(primary);
  color: white;
  font-size: $font-size-smallest;
  font-weight: bold;
  padding: .7em 1em;
  top: 1rem;
  right: -1px;
  line-height: 1;
  text-transform: uppercase;
  border-top-left-radius: $label-radius;
  border-bottom-left-radius: $label-radius;
  z-index: 1;

  .mediabox & {
    right: 0;
    z-index: 3;
    font-size: $font-size-smaller;
    top: 20px;
  }
}

.product-item__description {
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .product-item__wrapper--related & {
    min-height: 0;
  }
}

.product-item__title {
  font-family: Arial, sans-serif;
  letter-spacing: 0;
  font-weight: bold;
  color: color(text);
  font-size: 16px;
  text-transform: none;
  padding: $spacing-base * 0.85;
  margin-bottom: 0;
  min-height: 90px;

  .product-item__wrapper--related & {
    padding-top: 0.25rem;

    @include media('<md') {
      text-align: center;
    }
  }

  .wishlist & {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.product-item__specs {
  border-top: 1px solid color(light);
  margin-bottom: 0;
  font-size: 14px;

  tr {

    &:nth-child(even) {
      background-color: color(tab-bg);
    }
  }

  td {
    padding: $spacing-tiny $spacing-base * 0.85;

    &:last-child {
      text-align: right;
    }
  }
}
