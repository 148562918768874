.footer-sticky {
  display: none;

  @include media('>lg') {
    display: block;
    position: fixed;
    background: color(lighter);
    text-align: right;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $spacing-small;
    z-index: 10;

    .container > * {
      margin: 0 $spacing-tiny;

      &:last-child {
        margin: 0;
      }
    }
  }

  &.footer-sticky--splash {
    text-align: left;
    display: block;
    position: fixed;
    background: color(lighter);
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $spacing-small;
    z-index: 10;

    .container {
      display: flex;
      justify-content: space-around;
      align-items: center;

      @include media('<lg') {
        justify-content: flex-end;
        padding: 0;
      }
    }

    .container > * {
      margin: 0;
    }
  }
}

.footer-sticky__question {
  display: inline-block;
  font-weight: bold;
}

.footer-sticky--splash .copyright-nav {
  display: none;
  min-width: 65%;

  @include media('>lg') {
    display: block;
  }
}
