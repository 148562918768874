.tab__content.tab__content--downloads {
  max-width: none;
}
.download-teaser__list {
  @include grid-collapse();
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.download-teaser {
  @include list-clean();
  @include grid-column(12);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @include media('>md') {
    @include grid-column(6);
  }

  @include media('>lg') {
    @include grid-column(4);
  }

  .pdf-bg {
    fill: color(tab-bg);
  }

  .pdf-text {
    fill: color(primary);
  }

  .download-teaser__icon,
  img {
    margin-right: 15px;
    flex-shrink: 0;
    align-self: flex-start;
  }

  .download-teaser__link {
    display: flex;
    align-items: center;
    color: color(text);
    text-decoration: none;
    padding-top: 30px;
    border-top: 1px solid color(light);

    #{$all-hovers} {
      //color: color(primary);
      border-color: color(primary);

      .pdf-bg {
        fill: color(primary);
      }

      .pdf-text {
        fill: color(tab-bg);
      }
    }
  }

  .download-teaser__text {
    font-size: 14px;
  }
  .download-teaser__title {
    font-size: 16px;
    font-weight: bold;
  }

  .tab__content--downloads & {
    .download-teaser__link {
      border: none;
    }
  }
}

.download-teaser__download-link,
.download-teaser__btn {
  margin: 10px 0 5px 0;
}
