//.dialog-off-canvas-main-canvas > ul {
//  @include list-inline;
//  text-align: center;
//  margin: .5em auto;
//
//  a {
//    @extend .btn;
//    font-size: .875rem !important;
//    padding: .375rem .75rem !important;
//  }
//}

[data-quickedit-entity-id]{
  @include container();
}

/* Loading Animation */
.ajax-progress-throbber .throbber {
  background: none; /* LTR */
}

.ajax-progress-throbber .throbber,
.ajax-progress-throbber .throbber:after {
  content: '';
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.ajax-progress-throbber .throbber {
  display: inline-block;
  margin: 10px 0 0 -20px;
  font-size: 10px;
  position: absolute;
  left:50%;
  top: 100%;
  z-index: 10;
  border-top: 10px solid rgba(255, 255, 255, 0.2);
  border-right: 10px solid rgba(255, 255, 255, 0.2);
  border-bottom: 10px solid rgba(255, 255, 255, 0.2);
  border-left: 10px solid #ffffff;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
