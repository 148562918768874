/**
 * settings.typography
 * Base typographic settings
 */

@font-face {
  font-family: 'NeueHelvetica';
  src: url('../../fonts/NeueHelveticaPro-79CmBold.eot');
  src: url('../../fonts/NeueHelveticaPro-79CmBold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/NeueHelveticaPro-79CmBold.woff2') format('woff2'),
  url('../../fonts/NeueHelveticaPro-79CmBold.woff') format('woff'),
  url('../../fonts/NeueHelveticaPro-79CmBold.ttf') format('truetype'),
  url('../../fonts/NeueHelveticaPro-79CmBold.svg#NeueHelveticaPro-79CmBold') format('svg');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

// Fonts
$font-family-base: Arial, sans-serif !default;
$font-family-heading: 'NeueHelvetica', $font-family-base !default;

// Font Sizes
$font-size-base: 1rem !default;
$font-size-larger: 1.5rem !default;
$font-size-large: 1.25rem !default;
$font-size-small: 0.875rem !default;
$font-size-smaller: 0.75rem !default;
$font-size-smallest: 0.6rem !default;


$font-size-h1: 3.5625rem !default;
$font-size-h2: 2.25rem !default;
$font-size-h3: 1.4375rem !default;
$font-size-h1-mobile: 2rem !default;
$font-size-h2-mobile: $font-size-h3 !default;
$font-size-h3-mobile: 1.125rem !default;
// Line height
$line-height-base: 1.5 !default;
$line-height-heading: 1.2 !default;
