.btn {
  @include btn();

  &.btn--dark {
    @include btn-secondary();
  }

  &.btn--light {
    @include btn-tertiary();
  }

  &.btn--small {
    font-size: $font-size-small;
    padding: $spacing-tiny $spacing-small;
  }

  &.btn--large {
    font-size: $font-size-large;
    padding: 10px 15px;
  }

  [class^=block-] & {
    display: block;
  }

  &.btn--contact {
    margin-bottom: 1rem;
  }
}

.subtoggle {
  display: none;
}

.landings-page-section--orange {
  [type=submit],
  button,
  [type=reset],
  .btn {
    @include btn-secondary();
  }
}
