.footer-nav__list {
  @include list-inline;
}

.footer-nav__list-item {
  @extend .separator;
  font-size: $font-size-small;

  @include media('<=lg') {
    padding-right: 0;

    &:before {
      content: none !important;
    }
  }

  @include media('>lg') {
    padding-right: $spacing-tiny !important;
  }


  &:first-child:before {
    display: none;
  }

  a:hover {
    color: color(primary);
  }
}

.footer-nav__item-link {
  text-decoration: none;
  color: color(text);
  display: inline-block;
  padding-left: $spacing-small;
}
