.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    position: relative;
    top: -2px;
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #B8B9BA;
    border-radius: 3px;
  }

  // Box hover
  &:hover + label:before {
    background: #EAEBEB;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 none;
  }

  // Box checked
  &:checked + label:before {
    background: #EAEBEB;
    border: 1px solid color(primary);
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 6px;
    width: 10px;
    height: 8px;
    background: url('../svg/custom-checkbox.svg') center center no-repeat;
  }
}
