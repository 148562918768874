.nav-sub {
  display: none;
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  @include media('>lg') {
    display: block;
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #28292A;
    border: 1px solid #515255;
    padding: $spacing-large * 0.85 $spacing-large * 1.25;
    padding-top: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.75, -0.02, 0.2, 0.97), transform 400ms cubic-bezier(0.75, -0.02, 0.2, 0.97);
    transform: translate(0, 100px);
    height: auto;
    max-height: none;
    overflow-y: visible;
    overflow-x: visible;
  }

  @include media('>xl') {
    width: 75%;
  }

  &.is--active {
    display: flex;
    flex-wrap: wrap;
    left: 0%;

    @include media('<lg') {
      position: relative;
      z-index: 2;
      left: 0;
    }

    @include media('>lg') {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 115px);
    }
  }
}

.nav-sub.large {
  width: 100%;
  padding: 0;
  background-color: color(black);

  @include media('>lg') {
    right: 0;
    background-color: color(dark);
  }

  .nav-sub__list-view-container {

    div {
      &:first-child {
        margin-top: 10px;
      }
    }

    .nav-sub__list-view {
      margin-top: -5px;
    }

    @include media('>lg') {
      float: right;
      width: 75%;
      display: block;

      .nav-sub__list-view {
        margin-top: 0;
      }
    }
  }

  .main-items {
    float: left;
    margin-left: 0;
    margin-bottom: 0;
    display: none;

    @include media('>lg') {
      width: 25%;
      display: block;
      margin-top: 0;
    }

    li {
      font-size: 18px;
      margin-bottom: 0;

      @include media('>lg') {
        font-size: 16px;
        border-bottom: 1px solid #515255;

        &:hover {
          background-color: #28292A;
        }
      }

      @include media('>xl') {
        font-size: 18px;
      }

      a {
        position: relative;
        display: block;
        padding: 0 30px;

        @include media('>lg') {
          padding: $spacing-base * 0.85;
        }

        @include media('>xl') {
          padding: $spacing-base $spacing-large * 0.85;
        }

        span {
          @include chevron-navigation();
        }

        @include media('>lg') {
          &:hover span:before {
            background: url('../svg/chevron-grey.svg') center center no-repeat;
            background-size: 6px 14px;
          }
        }
      }
    }
  }
}

.nav-sub.large .main-items .is--active-nav {
  background-color: color(black);

  @include media('>lg') {
    background-color: #28292A;

    a:after {
      z-index: 2;
      content: '';
      position: absolute;
      right: -9px;
      top: -2px;
      background-color: #515255;
      height: 55%;
      width: 1px;
      transform: rotate(-22.5deg);
    }

    a:before {
      z-index: 2;
      content: '';
      position: absolute;
      right: -9px;
      bottom: -2px;
      background-color: #515255;
      height: 55%;
      width: 1px;
      transform: rotate(22.5deg);
    }

    span:before {
      background: url('../svg/chevron-grey.svg') center center no-repeat;
      background-size: 6px 14px;
    }

    &:after {
      content: '';
      position: absolute;
      right: -1px;
      top: 0;
      background-color: #28292A;
      height: 100%;
      width: 1px;
    }

    a {
      color: #67686a;
    }
  }
}

.nav-sub__list-view {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include media('>lg') {
    margin-top: 0;
    columns: 240px 3;
    display: block;
    padding: $spacing-base 0;
  }

  .nav-sub.large & {
    display: none;
    float: left;
    width: 100%;
    padding-bottom: 0;

    @include media('>lg') {
      padding: $spacing-large * 1.25;
      padding-top: 0;
      border-left: 1px solid #515255;
      background-color: #28292A;
      min-height: 304px;
    }

    &.is--active-nav {
      display: block;
      columns: 240px 3;

      @include media('>lg') {
        padding: $spacing-base 0 $spacing-base $spacing-large;
      }
    }
  }
}

.nav-sub__list {
  @include list-clean();
  @include grid-column(12);
  margin-left: $spacing-large * 0.85;
  margin-top: $spacing-base * 0.5;

  @include media('>lg') {
    //width: 33.3%;
    width: auto;
    float: none;
    margin-top: 0;
    margin-bottom: $spacing-large * 0.85;
    margin-left: 0;
  }
}

.nav-sub__item {
  @include chevron-list();
  color: color(white);
  padding-left: 1em;

  &:before {
    background-position: center 10px;
  }

  &.main-item {
    line-height: 1.5;
    font-size: 16px;
    font-weight: bold;
    padding-left: 0;

    .nav-sub__list-view-container & {
      padding-right: 1em;
    }

    @include media('>lg') {
      margin-bottom: .3em;
    }

    &:before {
      display: none;
    }
  }

  @include media('<=lg') {
    font-size: $font-size-smaller;
    line-height: 2.75;
  }

  @include media('>lg') {
    font-size: $font-size-small;
    line-height: 2;
  }

  a {
    color: color(white);
    text-decoration: none;

    #{$all-hovers} {
      color: color(text);
      text-decoration: none;
    }
  }
}

/* Large version nav-tabs */
.nav-tab-header__link {
  color: color(white);
  padding: $spacing-tiny 30px;
  display: inline-block;

  span {
    @include chevron-navigation();
  }

  .is--active-nav & {
    span:before {
      transform: rotate(90deg);
    }
  }
}

.nav-tab__header {
  border-bottom: 0;
  margin-top: $spacing-tiny;
  font-size: 18px;
  clear: left;

  &.is--active-nav {
    border-bottom: 0;

    .nav-tab-header__link {

    }
  }
  @include media('<=lg') {
    .nav-tab-header__link {
      padding-left: 18px;
    }
  }

  @include media('>lg') {
    display: none;
  }
}

