.text-link {
  @include text-link();
}

.text-link--small {
  @include text-link($font-size-small);
}

.text-link--large {
  @include text-link($font-size-large);
}

.link__read-more {
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.25em;

  &:active {
    color: color(primary);
  }

  svg {
    margin-left: 10px;
  }

  #{$all-hovers} {
    color: color(text);

    svg {
      stroke: color(text);
    }
  }
}
