.dealermap__filters {
  position: relative;
  margin-top: $spacing-base;

  .form-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    @include media('>lg') {
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .form-item.form-item--service {
    .form-group:last-child {
      @include media('<lg') {
        width: 100%;
        margin: 0;
      }
    }
  }

  .dealermap__filters-content {
    display: flex;
    justify-content: space-between;

    @include media('<lg') {
      flex-direction: column;
    }
  }


  .form-group {
    width: 50%;
    margin: 0 0 $spacing-small;

    &:last-child {
      margin: .5rem 0 0 auto;
    }

    @include media('>lg') {
      width: auto;
      margin: 0 $spacing-base 0 0;

      &:last-child {
        margin-left: 0;
        margin-top: 0;
      }
    }

    label span {
      color: color(primary);
      font-weight: bold;
    }
  }

  & > span {
    display: block;
    width: 100%;

    @include media('>lg') {
      display: inline-block;
      width: auto;
      margin-right: $spacing-base;
    }
  }
}
