.location-page__wrapper {
  @include grid-collapse();
}

.location-page__address,
.location-page__map {
  @include grid-column(12);
  @include media('>lg') {
    @include grid-column(6);
  }
}

.location-address__line {
  display: block;
}

.map_canvas {
  width: 100%;
  height: 300px;
  position: static;
  overflow: auto;
}

.location-list {
  @include list-clean();
  margin-bottom: 1.5rem;
}

.location-list__item {
  padding: .5rem 0;
  border-bottom: 1px solid color(tab-bg);
}

.location-list__link {
  @include text-link();

  &.active{
    color: color(primary);
  }
}
