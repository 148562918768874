#AanmelderCSS .form-control {
    background: white;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 8px 11px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    min-height: 40px;
    height: auto !important;  
}

#AanmelderCSS .form-control::placeholder {
font-weight: normal;
font-size: 1em;
color: #67686a;
line-height: 1.5;
}

#AanmelderCSS fieldset [class^=col-] {
    margin-bottom: 0;
    overflow: inherit !important;
}

#AanmelderCSS .form-group .form-control {
    border-color: transparent;
}

#AanmelderCSS .form-group .form-control:focus {
    border-width: 0 !important;
    border-color: transparent !important;
    padding-bottom: 0;
}

#AanmelderCSS .page-header {
    margin-bottom: 10px !important;
}

#AanmelderCSS .landingpage-container strong,
.landingpage-container strong {
    margin-top: 0 !important;
}

#AanmelderCSS .h1, #AanmelderCSS .h2, #AanmelderCSS .h3, #AanmelderCSS .h4, #AanmelderCSS .h5, 
#AanmelderCSS .h6, #AanmelderCSS h1, #AanmelderCSS h2, #AanmelderCSS h3, #AanmelderCSS h4, #AanmelderCSS h5, #AanmelderCSS h6 {
    font-family: NeueHelvetica,Arial,sans-serif !important;
}

#AanmelderCSS [class^=col-] {
    margin-left: 0 !important;
}

#AanmelderCSS fieldset {
    padding-bottom: 0 !important;
}

#AanmelderCSS fieldset ol li > span {
    display: block;
}

#AanmelderCSS fieldset ol li strong {
    display: inline-block;
}

#AanmelderCSS .landingpage-container strong {
    display: initial !important;
}

#AanmelderCSS input[type="radio"],
#AanmelderCSS input[type="checkbox"] {
    border: 0;
    border-radius: 0;
    box-shadow: none !important;
}

#AanmelderCSS .form-control {
    border-width: 0 !important;
}

#AanmelderCSS .wikicontent {
    max-width: inherit !important;
}


#AanmelderCSS .form-group.has-error .aanmelder-help-block, 
#AanmelderCSS .form-group.has-error .checkbox, 
#AanmelderCSS .form-group.has-error .checkbox-inline, 
#AanmelderCSS .form-group.has-error .control-label, 
#AanmelderCSS .form-group.has-error .radio, 
#AanmelderCSS .form-group.has-error .radio-inline, 
#AanmelderCSS .form-group.has-error .select2-results__option[aria-selected=true], 
#AanmelderCSS .form-group.has-error .select2-search--dropdown .select2-search__field, 
#AanmelderCSS .form-group.has-error .select2-selection--single .select2-selection__rendered, 
#AanmelderCSS .form-group.has-error.checkbox label, #AanmelderCSS .form-group.has-error.checkbox-inline label, 
#AanmelderCSS .form-group.has-error.radio label, #AanmelderCSS .form-group.has-error.radio-inline label {
    color: #fc3030 !important;
}

@media (max-width: 991px) {
    #AanmelderCSS .form-group {
        margin-bottom: 30px !important;
    }

    #AanmelderCSS fieldset [class^=col-] {
        width: 100%;
    }
}
