.tab__container {
  @include media('<=lg') {
    display: none;
    overflow: hidden;
    margin-top: 15px;
    margin-left: 0;

    &.current {
      display: block;
    }
  }

  @include media('<=md') {
    display: none;
    overflow: inherit;
  }

  @include media('>lg') {
    display: none;
    padding: 30px 50px;
    border: 1px solid color(tab-border);
    margin-left: 0;

    &.current {
      display: block;
    }

    &.tab__container--search {
      padding: 20px;
    }
  }

  &.tab__container--features {
    padding-left: 0;
    padding-right: 0;
  }

  .mediabox__images-item {
    display: none !important; // fix for syncforce issues
  }
}

.tab__content {
  max-width: 90%;

  p > strong {
    margin-right: 5px;
  }

  @include media('>lg') {
    max-width: 70%;

    &.tab__content--parts-list {
      max-width: 90%;
    }
    &.tab__content--features {
      max-width: 100%;
    }
  }

  &.tab__content--specs {
    max-width: none;

    @include media('>lg') {
      max-width: 90%;
    }
  }

  &.tab__content--features {
    max-width: 100%;
    overflow: hidden;
  }

  &.tab__content--related-products {
    max-width: none;
  }
}

.tab__content--two-column {
  @include media('>lg') {
    display: flex;
    max-width: 100%;

    .usp-list {
      //max-width: 70%;
      margin-right: 30px;
      width: 100%;
    }

    .featured-images__wrapper {
      flex-direction: column;
    }
  }

}

.tab__container .usp-list li {
  @include media('>lg') {
    max-width: calc(70% - #{$spacing-base});
  }
}

.tab__container .usp-list li[data-feature-id] {
  padding-left: 0;
  font-weight: bold;
  margin-bottom: $spacing-tiny;
  margin-top: $spacing-large;
  padding-top: $spacing-large;
  clear: right;
  max-width: 100%;

  &:before {
    display: none;
  }

  img {
    clear: left;

    @include media('>lg') {
      margin-bottom: $spacing-large;
      margin-top: 0;
      float: right;
      clear: right;
      max-width: 30%;
      padding-top: $spacing-small*2;

      &:last-child {
        margin-bottom: $spacing-large;
      }
    }
  }

  &:first-of-type {
    padding-top: 0;
  }
}

.technical-images__wrapper {
  display: inline-block;
}

.technical-image {
  display: inline-flex;
  flex-direction: column;
  margin-right: 15px;
}

.products__grid,
.category__grid .views-element-container {
  @include grid-collapse();
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
