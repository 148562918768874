.headroom {
  will-change: transform;
  transition: transform 200ms linear;
  position: fixed !important;
  width: 100%;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-60%);
}

.hol-page-wrapper {
  @include media('>lg') {
    padding-top: 171px;
  }
}
