@mixin text-link($font-size: $font-size-base) {
  display: inline-block;
  font-size: $font-size;
  color: color(text);
  line-height: 1;
  text-decoration: none;
  font-weight: bold;

  #{$all-hovers} {
    text-decoration: none;
    color: color(primary);
  }

  &:after {
    content: '\203A';
    font-size: $font-size;
    position: relative;
    padding-left: .3em;
    line-height: 1;
  }
}
