.tab__wrapper--wishlist {
    margin-top: 2rem;
    margin-left: 0;

    .tab__content {
        max-width: 100%;
    }
}

.wishlist {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.wishlist__item {
    @include grid-column(12);
    display: inline-block;
    position: relative;
    border: 1px solid color(light);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.1);
    transition: top .2s ease;
    top: 0;
    margin-bottom: 2rem;

    @include media('>md', '<=lg') {
        @include grid-column(6);
    }

    @include media('>lg') {
        @include grid-column(4);

        &:hover {
            top: -5px;
        }
    }
}

.wishlist__item-remove {
    margin: 10px;
    color: color(text) !important;
    font-weight: normal;
    font-size: .875em;
    padding-left: 10px;
    padding-right: 10px;

    &:after {
        display: none;
    }

    &:hover {
        background: white !important;
        color: color(primary) !important;
    }
}

.wishlist__item-remove-icon {
    position: relative;
    bottom: -3px;
    padding-right: 5px;
}

// Wishlist Sidebar
.collapse--wishlist {
  .captcha {
    transform: scale(0.60);
    margin-left: -24%;
  }
}
