/**
 * trumps.hide-show
 * Hides and shows content classes
 */

.hidden {
  display: none !important;
}

@include media('<=sm') {
  .hide-sm-down {
    display: none !important;
  }
  .show-sm-down {
    display: block !important;
  }
}

@include media('<=md') {
  .hide-md-down {
    display: none !important;
  }
  .show-md-down {
    display: block !important;
  }
}

@include media('<=lg') {
  .hide-lg-down {
    display: none !important;
  }
  .show-lg-down {
    display: block !important;
  }
}

@include media('>sm') {
  .hide-sm-up {
    display: none !important;
  }
  .show-sm-up {
    display: block !important;
  }
}

@include media('>md') {
  .hide-md-up {
    display: none !important;
  }
  .show-md-up {
    display: block !important;
  }
}

@include media('>lg') {
  .hide-lg-up {
    display: none !important;
  }
  .show-lg-up {
    display: block !important;
  }
}

@include media('>xl') {
  .hide-xl-up {
    display: none !important;
  }
  .show-xl-up {
    display: block !important;
  }
}

