.embed-container,
.youtube-embed-wrapper {
    position: relative;
    height: 0;
    overflow: hidden;
    margin-bottom: 2rem;
    padding-bottom: 62.5%;
    padding-top: 0 !important;
    display: inline-block;
    width: 100%;

    @include media('>md') {
        padding-bottom: 0 !important;
    }
    @include media('>md', '<=lg') {
        width: 480px !important;
        height: 270px !important;
    }
    @include media('>lg') {
        width: 640px !important;
        height: 320px !important;
    }

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

/**
  Emergency fix:
 */
iframe[allowfullscreen] {
    @include media('<md') {
        width: calc(100vw - 50px);
        height: calc(56.25vw - 50px); /*16:9*/
    }
    @include media('>md') {
        width: 100%;
        height: 504px;
        max-width: 100%;
    }
}
