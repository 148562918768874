.article__utilities {
    display: block;
    overflow: hidden;
    clear: both;
    border-top: 1px solid color(tab-border);
    border-bottom: 1px solid color(tab-border);
    padding: $spacing-small 0;

    @include media('>lg') {
        padding: $spacing-base 0;
    }
}

.social-share {
    display: inline-flex;
    padding: 10px;
}
