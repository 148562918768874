.nav-sidebar__toggle {
  @include btn;
  float: right;

  &:after {
    display: inline-block;
    transition: transform .2s;
    transform: rotate(90deg);
  }

  @include media('>lg') {
    display: none;
  }

  &.is--active:after{
    transform: rotate(270deg);
    top: .25em;
  }
}
