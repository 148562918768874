@mixin diagonal-background($color: color(dark), $offset: 55px) {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: -$offset;
    background-color: $color;
    width: calc(50% + #{$offset});
    height: 100%;
    transform: skewX(18deg);
  }
}
