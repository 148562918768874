/**
 * base.lists
 */

/**
 * unified vertical rhythm
 */
ul,
ol,
dl {
  margin-bottom: $spacing-base;
}

/**
 * Default indentation value for lists
 */
ul,
ol,
dd {
  margin-left: $spacing-base;
}
