.filters--list {
  padding: 1.5rem 0;
  border-bottom: 1px solid color(tab-bg);

  .category-list {
    margin-top: 0;
  }

  .h4 {
    font-family: $font-family-base;
    color: color(text);
    font-size: $font-size-base;
    margin-bottom: 1rem;
    font-weight: bold;
    display: inline-block;
    letter-spacing: normal;
  }

  .list-clean {
    margin: 0;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

.filter-block {
    h5 {
    font-size: $font-size-large;
    font-family: $font-family-base;
    text-transform: none;
    color: color(text);
    letter-spacing: normal;
    font-weight: bold;
    padding-bottom: .75rem;
    border-bottom: 1px solid color(tab-border);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@include media('<=lg') {
  .filter-block {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid color(light);

    h5 {
      display: none;
    }
  }

  .filter-toggle {
    width: 100%;
    font-size: $font-size-large;
    color: color(text);
    font-weight: bold;
    border-bottom: 1px solid color(tab-border);
    padding: 15px 0 10px;
    margin-bottom: 15px;
    text-decoration: none;

    @include media('<=lg') {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 0 10px 0 40px;
      background-color: color(off-white);
      border: 1px solid color(tab-border);
    }

    &:after {
      content: '\203A';
      display: inline-block;
      transform: rotate(-90deg);
      float: right;
      font-size: 2rem;
      transition: rotate 0.25s;
      margin-right: 4px;
    }

    &.hide-lg-up {
      &:after {
        transform: rotate(90deg);
        margin-right: 0;
      }
    }
  }

  .filter-toggle__icon {
    position: absolute;
    top: 10px;
    left: 10px;

    svg path {
      fill: color(text);
    }
  }

  .filter-toggle__close-button {
    display: block;
    margin-top: 15px;
  }

  .filter-block.hide-lg-down + .filter-toggle__close-button {
    display: none;
  }
}

.btn.btn--refresh {
  margin: 0 0 0 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  width: 36px;

  &:after {
    content: none;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-left-color: white;
    display: block;
    margin-right: -6px;
  }
}

.filter-block {
  .views-exposed-form {
    .js-form-item-type-textfield,
    fieldset.fieldgroup {
      padding-bottom: 1.5rem;
      margin-top: 1.5rem;
      border-bottom: 1px solid color(tab-bg);

      .fieldset-wrapper {
        margin-top: 1rem;
      }
    }
  }
}

.show-categories {
  .hide-text {
    display: none;
  }

  &.shown {
    .hide-text {
      display: block;
    }

    .show-text {
      display: none;
    }
  }
}
