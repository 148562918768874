.region-picker {
  display: none;
  width: 100%;
  background: color(black);
  position: fixed;
  right: 0;
  top: 57px;
  height: auto;
  margin: 0;
  padding: $spacing-small $spacing-small $spacing-base;
  overflow: auto;

  .toolbar-vertical & {
    top: calc(39px + 57px);
  }

  &.is--active {
    display: block;
  }

  @include media('>md') {
    width: 555px;
    top: 100%;
    position: absolute;
  }
}

.region-picker__pane {
  overflow: hidden;
  width: 100%;

  @include media('>md') {
    width: 46%;
    display: inline-block;
    float: left;
    text-align: right;

    &:first-child {
      width: 54%;
      padding-right: 4%;
    }
  }

  &:first-child {
    text-align: left;
  }
}

.region-picker__select {
  //margin-top: 1em;
  max-width: 11.5rem;
  background-color: white;
}

.region-picker__heading {
  color: white;
}

.region-picker__confirm {
  margin-bottom: 0;
}
