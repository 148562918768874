/**
 * components.button
 * Button selectors, only mapping selectors to mixins located in:
 * 01-tools/mixins/_button-styles.scss
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.btn,
#{$all-buttons} {
  @include btn;

  #map_canvas & {
    &:after{
      display: none;
    }
  }
}

.btn--no-chevron {
  &:after {
    content: none;
  }
}

.print-btn,
.btn-clean {
  @include btn-clean();
}

.btn--circle {
  border-radius: 50%;
  padding: calc(.5rem - 2.5px) .5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg.refresh {
    stroke: #fff;
    margin: 0;
  }

  #{$all-hovers} {
    border-radius: 50%;
  }
}
/**
 * ======================================
 * Type classes
 * Uncomment the ones you need in the project
 * ======================================
 */

// .btn-secondary {
//   @include btn;
//   @include btn-secondary;
// }

// .btn-tertiary {
//   @include btn;
//   @include btn-tertiary;
// }

// .btn-ghost {
//   @include btn;
//   @include btn-ghost;
// }

// .btn-ghost-primary {
//   @include btn;
//   @include btn-ghost-primary;
// }

// .btn-ghost-secondary {
//   @include btn;
//   @include btn-ghost-secondary;
// }

// .btn-ghost-tertiary {
//   @include btn;
//   @include btn-ghost-tertiary;
// }

// .btn-clean {
//   @include btn-clean;
// }
