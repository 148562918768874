.service-center__icon,
.partner-level__icon {
  width: 70px;
  height: auto;
  float: right;

  @include media('>sm') {
    width: 80px;
  }
}

.partner-level__icon {
  clear: right;
}

.dealer__contact-type {
  display: inline-block;
  width: 2em;
}

.dealer__name {
  @include grid-column(12);
  margin-top: $spacing-base;
  margin-bottom: $spacing-base;
}

.dealer__contact-form {
  margin-left: .5em;

  input,
  select,
  textarea,
  .like-an-input {
    margin-bottom: 2em;
  }

  @include media('>lg') {
    fieldset {
      margin-top: .5em;
      margin-left: 0;
    }

    label,
    .like-a-label {
      width: 35%;
      margin-right: 5%;
      display: inline-block;
      clear: left;
      float: left;
    }

    input,
    select,
    textarea,
    .like-an-input {
      width: 60%;
      display: inline-block;
      float: left;
      margin-bottom: 1em;
      max-width: 100%;

      .checkbox {
        width: auto;
        position: relative;
        bottom: -4px;
      }

      .btn {
        width: 100%;
        clear: both;
      }
    }
  }

  .form__checkbox {
    margin-bottom: 2em;

    input {
      margin-bottom: 0;
    }
  }

  .label--right {
    clear: none;
    width: auto;
  }

  .btn {
    width: 100%;
    clear: both;
  }
}
