.back-btn {
  display: none;

  @include media('>lg') {
    display: inline-flex;
  }

  .back-btn__pmc-link {
    text-decoration: none;
    font-size: 1.25em;
    display: inline-block;

    #{$all-hovers} {
      text-decoration: none;
      color: color(primary);
    }
  }

  .chevron {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
  }
}

.back-btn__separator {
  @include diagonal-line(text, 20px);
  margin: 0 15px 0 0;
}

.back-btn__pmc-link {
  color: color(text);
  font-weight: bold;
}

.back-btn__home-link {
  display: inline-flex;
  align-items: center;
  height: 2em;
  padding: 0 $spacing-small;
  margin-left: -$spacing-small;
  overflow: hidden;
  position: relative;
  width: 0;
  transition: width .3s ease;

  .headroom--unpinned & {
    width: 120px;
  }
}
