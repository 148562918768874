/**
 * generic.box-sizing
 * Set the global `box-sizing` state to `border-box`. As per:
 * http://paulirish.com/2012/box-sizing-border-box-ftw
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
