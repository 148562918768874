.pdp__myholmatro_app_banner {
  margin-top: 2rem;
  padding: 16px;
  border-radius: 4px;
  background: linear-gradient(278.25deg, #A33D00 -51.99%, #F45C00 70.53%);

  &__title,
  &__subtitle {
    color: white;
  }

  &__subtitle {
    text-transform: none;
    margin-bottom: 40px;
  }

  &__button {
    text-decoration: none;
  }

  &__button-image {
    height: 56px;
  }
}

