.teaser__grid {
  background: color(light);
  padding-top: 80px;
  padding-bottom: 80px;

  .container {
    padding: 0;

    @include media('>md'){
      padding: 0 $spacing-base;
    }
    @include media('>container'){
      padding: 0;
    }
  }

  h2 {
    padding-left: $spacing-base;
    padding-right: $spacing-base;

    @include media('>md') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .collapse {
    @include media('<md') {
      overflow: none; //safari hack
      overflow-x: scroll;
      width: 100%;
      margin: 0;
    }
  }
}

.teaser__scroll-wrapper {
  @include media('<md') {
    padding-left: $spacing-base;
    padding-right: $spacing-base;
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0px); // ios force GPU
    -webkit-transform: translate3d(0,0,0); // ios force GPU
    -webkit-perspective: 1000; // ios force GPU
    white-space: nowrap;
    display: flex;
  }



  @include media('>=md') {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
}

@include media('<md') {
  @supports (-webkit-overflow-scrolling: touch) {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background: color(primary);
      height: 7px;
    }
  }
}

.teaser__wrapper {
  margin-bottom: 20px;

  .tab__container &{
    border: 1px solid color(lighter);
  }

  @include media('<md') {
    width: 300px;
    min-width: 300px;
    margin-left: 20px;
    float: none;
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }
  }

  @include media('>md', '<=lg') {
    @include grid-column(6);
  }

  @include media('>lg') {
    @include grid-column(4);
  }
}

.teaser__wrapper--big {
  @include media('>lg') {
    @include grid-column(8);
  }
}

.category-teasers__grid {
  padding: 20px 0;
  @include media('>lg') {
    padding: 70px 0;
  }
}
