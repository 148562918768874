.pdp__description {
  position: relative;
  max-height: 87px;
  margin-bottom: 2rem;

  @include media('>lg') {
    max-height: 245px;
  }

  overflow: hidden;

  &:after {
    content: '';
    display: block;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  }

  &.show-full {
    max-height: none;
    overflow: auto;

    &:after {
      content: none;
    }

    ~ .btn {
      position: static;
    }
  }

  .pdp__description__text {
    font-size: 1.25em;
  }
}

.link__read-more {
  background: #fff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  .read-more.hidden ~ .chevron.down.orange {
    transform: rotate(-90deg);
  }

  &.hidden + .btn {
    position: absolute;
  }

  &.hidden + .btn.btn--contact {
    position: relative;
  }

}
.pdp__standard-applied-with {
  margin-bottom: 0.25rem;
}

.pdp__specs-table {
  width: 100%;

 // border: 1px solid color(tab-border);
  tr {
    font-size: $font-size-small;
    &:nth-child(even) {
      background: color(tab-bg);
    }
  }

  td,
  th {
    padding: 5px 0 5px 10px;
  }

  td {
    vertical-align: top;
    text-align: left;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}
