/**
 * trumps.colors
 * Classes to help with colors
 */

/**
 * ======================================
 * Color
 * ======================================
 */

.color-primary {
  color: color(primary);
}

.color-secondary {
  color: color(dark);
}


/**
 * ======================================
 * Fill
 * ======================================
 */

.fill-primary {
  fill: color(text);
}

.fill-secondary {
  fill: color(light);
}


/**
 * ======================================
 * Background
 * ======================================
 */

.background-primary {
  background-color: color(primary);
}

.background-secondary {
  background-color: color(dark);
}
