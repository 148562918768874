/**
 * base.typography
 */

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: color(text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6,
.secondary-font,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $spacing-small;
  font-family: $font-family-heading;
  line-height: $line-height-heading;
  color: color(primary);
  text-transform: uppercase;
  letter-spacing: 1px;
}

h1, .h1 {
  @include media('<=md') {
    font-size: $font-size-h1-mobile;
  }
  @include media('>md') {
    font-size: $font-size-h1;
    line-height: 1;
  }
}

h2, .h2 {
  @include media('<=md') {
    font-size: $font-size-h2-mobile;
  }
  @include media('>md') {
    font-size: $font-size-h2;
  }

  .column-main--search & {
    @include media('<=md') {
      font-size: $font-size-h3-mobile;
    }
    @include media('>md') {
      font-size: $font-size-h3;
    }
  }

  .column-main--search .product-item__description & {
    font-size: 1em !important;
  }
}

h3, .h3 {
  @include media('<=md') {
    font-size: $font-size-h3-mobile;
  }
  @include media('>md') {
    font-size: $font-size-h3;
  }
}

.h4{
  .filters--list & {
    text-transform: none;

    &:first-letter{
      text-transform: uppercase;
    }
  }
}

h4, h5, h6, .h4, .h5, .h6 {
  font-size: $font-size-base;
}


/**
 * Unified margins on typographical elements
 */
blockquote,
p,
address,
table,
pre,
hr {
  margin-bottom: $spacing-base;
}

/**
 * Links
 */
a {
  color: color(primary);
  text-decoration-skip: ink;
  transition: color $animation-duration-base;

  #{$all-hovers} {
    text-decoration: none;
  }
}

hr {
  border-bottom: 1px solid color(grey);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin-bottom: $spacing-base;
}

blockquote {
  @include diagonal-line();
  padding-left: 2em;
  color: color(text);
  font-style: italic;

  &:before {
    position: absolute;
    left: 1em;
    background: color(primary);
  }
}

.normal-text {
  font-size: $font-size-base;
}

.small-text {
  font-size: $font-size-small;
}

.large-text {
  font-size: $font-size-large;
}

.bold-numbers {
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;

  > li {
    margin: 0;
    padding: 0 0 0 2em;
    text-indent: -2em;
    list-style-type: none;
    counter-increment: item;

    &:before {
      display: inline-block;
      width: 1em;
      padding-right: 0.5em;
      font-weight: bold;
      text-align: right;
      content: counter(item) ".";
    }
  }
}

address{
  font-style: normal;
}
