/**
 * settings.responsive
 * Used in conjunction with Include Media (https://include-media.com/)
 */

$max-width: 1200px;

$breakpoints: (
  xs: 320px,
  xm: 375px,
  sm: 480px,
  md: 640px,
  lg: 960px,
  container: 1200px,
  xl: 1280px,
  uhd: 2000px,
  mw: $max-width + 30px
) !default;


