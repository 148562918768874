/**
 * base.media
 */

figure {
  margin-bottom: $spacing-base;
}

/**
 * Image
 * 1. By default all images are fluid
 * 2. Render `alt` visually offset when images don't load
 */
img {
  max-width: 100%; /* [1] */
  height: auto;
  font-style: italic; /* [2] */
}
