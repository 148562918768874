/**
 * base.forms
 * Basic styling for form fields, labels, etc.
 */

/**
 * reset
 */

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

fieldset,
legend {
  margin: 0;
}

legend {
  display: none;
}

textarea,
label {
  display: block;
}

/**
 * Label
 * 1. If the label is connected to an input, have user feedback for interaction
 */
label {
  font-weight: normal;

  &[for] {
    cursor: pointer; /* [1] */
  }
}

/**
 * Text input & selects
 */
#{$all-text-inputs},
select {
  @include shared-input;
  width: 100%;
  max-width: 24rem;

  #{$all-hovers} {
    border-color: color(grey);
  }

  /**
   * Validation classes
   * 1. Needs to be immutable, disabled is disabled
   */
  &.is-valid {
    border-color: $color-success !important;
  }

  &.is-invalid {
    border-color: $color-error !important;
  }

  /**
   * 1. Needs to be immutable, disabled is disabled
   */
  &:disabled {
    color: color(grey) !important; /* [1] */
    background-color: color(light) !important; /* [1] */
    border-color: color(grey) !important;
    cursor: not-allowed;
  }

  &::placeholder {
    color: color(grey);
  }
}

#{$all-text-inputs} {
  background: color(white);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px 11px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  min-height: 40px;

  #{$all-hovers} {
    //border: none;
  }

  &::placeholder {
    font-family: $font-family-base;
    font-weight: normal;
    font-size: 1em;
    color: color(text);
    line-height: 1.5;
  }
}

textarea {
  resize: vertical;
}

select {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjY0MS41N2w1LjExMyA0LjE0MkwxMC42MTMuNTciIHN0cm9rZT0iI0Y0NUMwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-position: right $spacing-small top 50%;
  padding-right: 2em;

  &::-ms-expand {
    display: none;
  }
}

[type='checkbox'],
[type='radio'] {
  display: inline-block;
  margin-right: $spacing-small;

  + label {
    display: inline-block;

    .landings-page-section--orange &,
    .landings-page-section--black &,
    .landings-page-section--grey-dark & {
      color: white;
    }

    .landings-page-section--grey & {
      color: black;
    }
  }
}

[type='file'] {
  width: 100%;
}

.form-required:after {
  content: " *";
  color: red;
}
