.news-overview__filters {
  padding: $spacing-base 0 0;

  .btn {
    margin-bottom: 1em;
    margin-right: .75em;
  }
}

.news-overview__year-indicator {
  color: color(text);
  display: block;
  font-size: .6em;
}

.news__archive .nav-sidebar__list {
  margin-bottom: 0;
}

.news__archive .btn {
  background: none;
  padding: 0 0 1.5em 1em;
  border: none;
  color: color(text);
  text-decoration: none;
  display: block;
  box-shadow: none;
  text-align: left;
  font-size: 1em;
  font-weight: normal;

  &:after{
    display: none;
  }

  &:hover {
    color: color(primary);
    background: transparent;
  }
}
