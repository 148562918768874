.pdp__wrapper {
  margin: 50px 0;

  &.bg__white {
    background-color: white;
  }
}

.mediabox,
.pdp__main {
  @include grid-column(12);
}
.mediabox {
  @include media('<=lg') {
    margin-top: 2rem;
  }
  @include media('>lg') {
    @include grid-column(6.5);
  }
}

.main-content {
  float: left;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  @include media('>lg') {
    float: right !important;
    margin-left: 20px;
    margin-right: 20px;
    @include grid-column(9.25);
  }
}

.pdp__main {
  margin-right: 20px;

  @include media('>lg') {
    @include grid-column(5.25);
    float: right;
    margin-top: 1rem;
  }

  @include media('>xl') {
    min-height: 467px;
    position: relative;

    .btn {
      position: relative;
      bottom: 0;
    }
  }
}

.tab__wrapper {
  @include grid-column(12);
}

.tab__wrapper--search {
  margin-left: 0;
}
