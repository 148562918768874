.fixed-menu__wrapper {
	position: fixed;
	right: 40px;
	top: calc(50% - 125px);
	z-index: 10000;

	@include media('>md') {
		height: 60%;
	}
}

.fixed-menu__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.fixed-menu__link {
	text-decoration: none;
	font-size: 14px;
	line-height: 2;
	text-transform: lowercase;
	color: white;
	display: block;
	text-align: right;

	.has--shadow & {
		text-shadow: 0 0 10px #000;
	}

	&:hover {
		color: color(primary);
	}

	.active & {
		color: color(primary) !important;
	}

	.fixed-menu__list--dark & {
		color: black;

		&:hover {
			color: color(primary);
		}
	}

	.fixed-menu__list--light & {
		color: white;

		&:hover {
			color: color(primary);
		}
	}
}

@include media('<lg') {
	.fixed-menu__list {
		position: relative;
		right: -100vw;
		background-color: rgba(#232323, 0.9);
		padding: 15px;
		box-sizing: border-box;
		transition: right 150ms ease-in-out;

		.show-menu & {
			right: 0;
		}

		&.fixed-menu__list--dark {
			background-color: rgba(white, 0.9);
		}
	}


	.fixed-menu__wrapper {
		right: -100vw;
    transition: right .15s ease-in-out;

    .show-menu & {
      right: 0;
    }
	}
}
