@include media('<=lg') {
  .tab__header {
    font-size: $font-size-h2-mobile;
    border-bottom: 1px solid color(tab-border);
    margin-top: 20px;
  }
  .tab-header__link {
    font-weight: bold;
    text-decoration: none;
    color: color(text);
    display: block;
    padding: 0.5rem;
    position: relative;
  }

  .tab-header__svg {
    transition: transform 0.25s ease;
    position: absolute;
    right: 15px;
    top: 40%;

    .current & {
      transform: rotate(270deg);
    }
  }
}

@include media('<=md') {
  .page-node-type-dealer .tab__header:last-of-type {
    display: none;
  }
}

@include media('>lg') {
  .tab__header {
    display: none !important;
  }
}
