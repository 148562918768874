@mixin diagonal-line($color: diagonal, $height: 20px) {
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    height: $height;
    width: 1px;
    background: color($color);
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(-18deg) translateY(20%);
  }
}
