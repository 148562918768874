.layout-fixed-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  min-height: 500px;
  background-repeat: no-repeat;
  z-index: 0;

  .splash-video:before {
    transform: none;
    left: 0;
  }


  // Theme light: dark text color
  &.layout-fixed-background--light {
    .layout-fixed-background__video,
    .layout-fixed-background__image {
      &::before {
        background-color: rgba(white, 0.7);
      }
    }
  }

  // Theme dark: white text color
  &.layout-fixed-background--dark {
    .layout-fixed-background__video,
    .layout-fixed-background__image {
      &::before {
        background-color: rgba(black, 0.7);
      }
    }
  }
}

.layout-fixed-background__video,
.layout-fixed-background__image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, 0.7);
  }
}

.fixed-background-visible {
  .container.content-page,
  .landingpage-container,
  .landings-page-section {

    color: white;

    .nav-sidebar__sub-list-item a {
      color: white;

      &:hover {
        color: color(primary);
      }
    }
  }

  &.fixed-background-visible--dark {
    .container.content-page,
    .landingpage-container,
    .landings-page-section {
      color: white;

      .nav-sidebar__sub-list-item a {
        color: white;

        &:hover {
          color: color(primary);
        }
      }
    }
  }

  &.fixed-background-visible--light {
    .container.content-page,
    .landingpage-container,
    .landings-page-section {
      color: black;

      .nav-sidebar__sub-list-item a {
        color: black;

        &:hover {
          color: color(primary);
        }
      }
    }
  }
}
