.content-banner {
    @include diagonal-background(color(primary), 90px);
    margin-bottom: 15px;
    overflow: hidden;
    height: 200px;

    &--orange {
        &::before {
            background-color: color(primary);
        }
    }

    &--black {
        &::before {
            background-color: color(black);
        }
    }

    @include media('<lg') {
        height: auto;

        &::before {
            display: none;
        }
    }
}

.content-banner__body {
    max-width: 50%;
    padding: 15px;
    color: white;
    box-sizing: border-box;

    h1,
    h2,
    p {
        color: currentColor;
    }

    @include media('<lg') {
        max-width: 100%;
        background-color: color(primary);
    }

    .content-banner--orange & {
        @include media('<lg') {
            background-color: color(primary);
        }
    }

    .content-banner--black & {
        @include media('<lg') {
            background-color: color(black);
        }
    }
}

.content-banner__visual {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;

    img {
        width: auto;
        height: 100%;
    }
    
    @include media('<lg') {
        display: none;
    }
}

.content-banner__cta-button {
    @include btn();
    background-color: transparent;
    border: 1px solid white;

    &:hover {
        background: darken(color(primary), 5%);
    }

    &--white {
        border-color: color(white);
        color: color(white);

        &:hover {
            background: darken(color(primary), 5%);
        }
    }

    &--orange {
        border-color: color(primary);
        color: color(primary);

        &:hover {
            background: darken(color(primary), 5%);
        }
    }

    &--black {
        border-color: color(black);
        color: color(black);

        &:hover {
            background: darken(color(black), 5%);
        }
    }
}
