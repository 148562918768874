// CK Editor
.landingpage-container {
  @include list-clean();

  [class^=block-] {
    .btn {
      display: inline-block;
    }
  }

  strong {
    display: block;
    margin-top: 15px;
  }

  ul {
    margin-top: .75rem;
    margin-bottom: 0;
    margin-left: 0;
    list-style-type: none;
  }

  ul li {
    position: relative;
    margin-bottom: 0.25em;
    padding-left: 1.75em;

    &::before {
      display: inline-block;
      width: 15px;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-image: url('../svg/check.svg');
      background-position: center 6px;
      background-repeat: no-repeat;
      background-size: 15px 11px;
      content: '';
    }
  }

  .landings-page-section--orange,
  .landings-page-section--black,
  .landings-page-section--grey-dark {
    ul li::before {
      background-image: url('../svg/check-white.svg');
    }
  }
}

.landings-page-section {
  position: relative;
  padding: 80px 0;
  color: color(text);
  box-sizing: border-box;

  iframe {
    max-width: 100%;
    @include media('<=md') {
      height: auto;
    }

  }

  @include media('<=md') {
    padding: 30px 0;
  }

  &--no-spacing {
    padding: 0;
  }

  &--no-spacing-top {
    padding-top: 0;
  }

  &--no-spacing-bottom {
    padding-bottom: 0;
  }

  &--image {
    line-height: 0;
  }

  &:last-child {
    margin-bottom: 30px;
  }
}

.landings-page-section--video {
  .container {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  [class^=col-1] {
    width: 100%;
    float: inherit;
    margin: 0;
  }

  iframe[allowfullscreen] {
    height: 100vh;

    @include media('<md') {
      width: 100%;
      height: calc(56.25vw - 50px); /*16:9*/
    }
  }
}

.landings-page-section--head {
  min-height: inherit;
  padding-bottom: 0;

  [class^=col-1] {
    margin: 0;
  }
}

.landings-page-section--head + .landings-page-section {
  padding-top: 0;
}

// THEMES
.landings-page-section--black {
  background-color: black;
  color: white;
}

.landings-page-section--grey-dark {
  background-color: color(text);
  color: white;
}

.landings-page-section--grey {
  background-color: color(grey);
  color: black;
}

.landings-page-section--orange {
  background-color: color(primary);
  color: white;

  h1, h2, h3, h4, h5 {
    color: currentColor;
  }
}

// THEME width image
.landings-page-section--has-image {
  display: flex;
  align-items: center;
  height: 70vh;
  background-size: cover;
}

.landings-page-section__content {
  height: 100%;

  // Fix for width children columns
  [class^=col-] {
    width: 100%;

    @include media('<md') {
      [class^=block-] {
        img {
          width: 100%;
        }
      }
    }

    @include media('<lg') {
      margin-left: 0;
    }

    @include media('>md', '<=lg') {
      margin-left: 0;
      display: flex;
      justify-content: space-between;

      [class^=block-] {
        margin-right: 0;
      }
    }
  }

}

.landings-page-section__full-width {
  width: 100%;
}

// Mobile toggle menu
.landing-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  position: fixed;
  left: 30px;
  bottom: 30px;
  border-radius: 50%;
  background-color: color(primary);
  box-shadow: 0 0 2px rgba(black, 0.5);
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: transform 250ms ease-in-out;
  z-index: 9999;

  @include media('>=lg') {
    display: none;
  }

  svg {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

// Impressive selector huh? Exception in case a sidebar is added to a landing-page. Sorry 😢.
.page-node-type-landingspage .column-sidebar + .column-main {
  [class^=col-] {
    padding: 2em;
  }
}


.fitVids-wrapper {
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
