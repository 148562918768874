.search-results__wrapper {
    margin-bottom: 1rem;
}

.search-result__block {
    margin-top: 30px;
    border: 1px solid color(tab-border);
    padding: 10px;
    display: flex;
    align-items: center;
    min-height: 125px;

    @include media('<md') {
        flex-direction: column;
        justify-content: center;
    }
}

.search-result__img {
    max-width: 250px;
    max-height: 200px;
    display: block;

}

.search-result__img-wrapper {
    @include media('>=md') {
        width: 280px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @include media('<md') {
        margin-bottom: .5em;
    }
}

.search-result__title {
    color: color(text);

    a {
        color: inherit;
        text-decoration: none;

        #{$all-hovers} {
            color: color(primary);
        }
    }
}

.img--mobile {
    display: block;

    @include media('>md') {
        display: none;
    }
}

.img--desktop {
    display: none;

    @include media('>md') {
        display: block;
    }
}

.search-result__title,
.search-result__body p, h2 {
    margin-bottom: 0;
}

/* FF lekker de hele boel resetten */
.search-result__body {
    .breadcrumbs__wrapper {
        background: none;

        .container {
            max-width: none;
            padding: 0;
            margin: 0;

            .breadcrumbs {
                font-size: .75em;

                .breadcrumb {
                    &:after {
                        left: auto;
                        right: 4px;
                        top: 5px;
                        @include media('>=md') {
                            top: 0;
                        }
                    }

                    &:first-child {
                        &:after {
                            display: inline-block;
                        }
                    }

                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }

                    .breadcrumb__link,
                    .breadcrumb__current {
                        padding: 0 .75rem 0 0;
                    }
                }
            }
        }
    }
}

.searchresult__occurrence {
    color: color(primary);
    font-size: .875rem;
}

.news-overview__wrapper,
.blog-overview__wrapper {
    margin-bottom: 2rem;
}
