.newsletter__form {
  display: flex;
  flex-wrap: wrap;

  .form-item,
  .form-actions {
    display: inline-block;
  }

  .form-actions {
    width: 130px;
  }

  .form-item {
    width: calc(100% - 140px);

    input {
      width: 100%;
      max-width: 100%;
    }
  }
}

.newsletter__input {
  width: 100%;
}

.footer__newsletter {
  position: relative;
}

.newsletter__submit {
  height: 42px;
}
