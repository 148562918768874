.breadcrumbs__wrapper {
  background: color(tab-bg);
}

.breadcrumbs {
  @include list-inline();
  font-size: .875em;
  margin: 0;
  padding: 0;
}

.breadcrumb {
  position: relative;
}

.breadcrumb::after {
  content: '';
  display: inline-block;
  height: 1em;
  width: 1px;
  background: color(grey);
  position: absolute;
  top: $spacing-small;
  right: 0;
  bottom: 0;
  left: -1px;
  transform: rotate(-18deg) translateY(-20%);

  @include media('>md') {
    transform: rotate(-18deg) translateY(20%);
  }
}

.breadcrumb:first-child::after {
  display: none;
}

.breadcrumb__current,
.breadcrumb__link {
  padding: $spacing-base/4;

  @include media('>md') {
    padding: $spacing-base/2;
  }
}

.breadcrumb__link {
  display: block;
  color: color(primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .breadcrumb:first-child &{
    padding-left: 0;
  }
}

.breadcrumb__current {
  display: inline-block;
  color: color(primary);
}


