@mixin chevron-list() {
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateY(0);
    content: '';
    display: inline-block;
    width: 4px;
    height: 100%;
    background: url('../svg/chevron-bg.svg') center center no-repeat;
    background-size: 4px 8px;
  }
}

@mixin chevron-block() {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateY(0);
    content: '';
    display: inline-block;
    width: 4px;
    height: 100%;
    background: url('../svg/chevron-bg.svg') center center no-repeat;
    background-size: 4px 8px;
  }
}

@mixin chevron-navigation() {
  position: relative;

  &:before {
    transition: transform ease .1s;
    position: absolute;
    top: 1px;
    right: -15px;
    bottom: 0;
    transform: translateY(0);
    content: '';
    display: inline-block;
    width: 6px;
    height: 100%;
    background: url('../svg/chevron-white.svg') center center no-repeat;
    background-size: 6px 14px;
  }
}
