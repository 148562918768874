.position-left {
  background-position: left !important;
}

.position-right {
  background-position: right !important;
}

.position-center {
  background-position: center !important;
}
.position-top {
  background-position: top !important;
}

.position-bottom {
  background-position: bottom !important;
}
