.usp-list {
  @include list-clean();

  .content-page & {
    margin-bottom: 2em;
  }

  strong {
    margin-top: 15px;
    display: block;
  }

  li {
    padding-left: 1.75em;
    position: relative;
    margin-bottom: 0.25em;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      width: 15px;
      background: url('../svg/check.svg') center 5px no-repeat;
      background-size: 15px 11px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  ul {
    list-style-type: none;
    margin-left: 2rem;
    margin-bottom: 0;

    li {

      &:before {
        content: '-';
        background: none;

      }
    }
  }
}
