.splash-slideshow {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &:after,
  &:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: black;
  }
  &:after {
    z-index: 3;
    background: rgba(0, 0, 0, .5);
  }
}

.splash-slideshow__img {
  position: absolute;
  transition: opacity 1s ease;
  width: 100%;
  height: 100%;
}

.splash-slideshow__img + .splash-slideshow__img {
  opacity: 0;
}

.pmc-intro__slide,
.splash-slideshow__img {
  position: absolute;
  transition: opacity 1s ease;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  /* Set first slide as active per default */
  opacity: 0;

  &:first-child {
    opacity: 1;
  }
  /* -- */
}
