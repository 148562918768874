@include media('<=lg') {
  .tab-list {
    display: none !important;
  }
}

@include media('>lg') {
  .tab-list {
    @include list-clean();
  }

  .tab-list__item {
    display: inline-block;
    background: color(tab-bg);
    border: 1px solid color(tab-border);
    border-bottom: none;
    border-right: none;
    position: relative;
    margin-right: -3px;

    &:after {
      content: '';
      display: block;
      width: 15px;
      height: calc(100% + 1px);
      position: absolute;
      right: -7px;
      top: -1px;
      background: #fff;
      border-left: 1px solid color(tab-border);
      transform: skewX(18deg);
    }

    &.current {
      border-bottom: none;
      background: #fff;


      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 1px;
        width: 100%;
        background: #fff;
      }
    }
  }

  .tab-list__link {
    color: color(text);
    font-weight: bold;
    padding: 10px 27px 10px 20px;
    display: block;
    text-decoration: none;

    .tab-list__item.current & {
      color: color(primary);
    }
  }
}

.featured-images__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: space-evenly;
}

.featured-image {
  margin: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
