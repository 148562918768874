.footer {
  background: color(dark);
  color: color(grey);
  padding-bottom: 50px;
  position: relative;

  @include media('>lg') {
    @include diagonal-background();
    padding-bottom: 30px;
    background: color(diagonalbg);
  }
}

.footer__container {
  padding-top: 15px;
  padding-bottom: 15px;

  @include media('>lg') {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.footer__wrapper {
  @include media('>lg') {
    @include grid-collapse();
  }
}

.footer__heading,
.footer h5 {
  color: color(white);
  font-family: $font-family-heading;
  text-transform: uppercase;
  @include media('<=lg') {
    font-size: $font-size-h3-mobile;
  }
  @include media('>lg') {
    font-size: $font-size-h3;
  }
}

.footer__left {
  @include media('>lg') {
    @include grid-column(5);
  }
}

.footer__right {
  @include media('<=lg') {
    margin-top: 2em;
    @include grid-collapse();
    overflow: hidden;
  }
  @include media('>lg') {
    @include grid-shift(1);
    @include grid-column(6);
  }
}

.footer__more-info {
  @include media('<=lg') {
    @include grid-column(5);

    > .footer__heading {
      //margin-bottom: .5rem;
    }
  }
  @include media('>lg') {
    @include grid-column(4);
  }
}

.footer__address {
  @include media('<=lg') {
    @include grid-column(7);
  }
  @include media('>lg') {
    @include grid-column(8);
  }
}

.footer-address__column-country {
  margin-bottom: 1em;
}

.footer-address__wrapper {
  margin-bottom: 2em;
  color: color(grey);
  font-style: normal;

  @include media('<=lg') {
    margin-bottom: 0;
    font-size: $font-size-smaller;
  }

  @include media('>lg') {
    @include grid-collapse();
    font-size: $font-size-small;
    overflow: hidden;
  }

  span {
    display: block;
  }

  .footer-address__us {
    display: inline-block;
  }

  a {
    color: color(white);
    text-decoration: none;

    #{$all-hovers} {
      color: color(grey);
      text-decoration: none;
    }
  }
}

.footer-address__column {
  line-height: 2.75;

  @include media('<=lg') {
    margin-bottom: 1em;
  }
  @include media('>lg') {
    //@include grid-column(6);
    text-align: right;
    float: right;
    margin-left: 20px;
    line-height: 2;
    &:first-child {
      text-align: left;
      float: left;
    }

    @include media ('<xl') {
      &:last-child {
        margin-left: 0;
        width: calc(50% - 10px);
      }
    }
  }
}

.footer__text {
  @include media('<lg') {
    font-size: $font-size-small;
  }
  margin: 15px 0 30px;
}

.newsletter__form-wrapper {
  @include media('>lg') {
    display: flex;
    justify-content: space-between;
  }
}

.newsletter__input,
.newsletter__submit {
  @include media('<=lg') {
    clear: both;
  }
}

.newsletter__input {
  @include media('<=lg') {
    display: block;
  }

  @include media('>lg') {
    display: inline-block;
  }
}

.newsletter__submit {
  @include media('<=lg') {
    margin-left: 10px;
  }

  @include media('>lg') {
    margin-left: 10px;
  }
}

.footer__sub-nav {
  @include media('>lg') {
    @include grid-column(12);
    margin-top: 90px;
  }

  .footer-nav__list {
    @include media('<=lg') {
      clear: both;
    }
  }

  .footer-nav__list-item {
    color: color(text);
    @include media('<=lg') {
      font-size: $font-size-smaller;
    }
    @include media('>lg') {
      font-size: $font-size-small;
    }
  }

  .footer-nav__item-link {
    color: color(text);
    text-decoration: none;
  }

  a.footer-nav__item-link:hover {
    color: color(white);
    text-decoration: none;
  }
}

.footer__list {
  @include list-clean();
}

.footer__list + .footer__list {
  margin-top: 1em;
}

.footer-list__item {
  @include chevron-list();
  color: color(white);
  padding-left: 1em;

  @include media('<=lg') {
    font-size: $font-size-smaller;
    line-height: 2.75;
  }

  @include media('>lg') {
    font-size: $font-size-small;
    line-height: 2;
  }

  a {
    color: color(white);
    text-decoration: none;

    #{$all-hovers} {
      color: color(text);
      text-decoration: none;
    }
  }
}

.copyright-nav {
  display: block;
  @include media('<=lg') {
    margin-top: 1em;
  }

  @include media('>lg') {
    float: left;
  }
}

.footer__partner-link {
  display: inline-block;
  width: 45%;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1em;

  @include media('<sm') {
    width: 100%;
  }
}
