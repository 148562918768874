.wishlist-counter {
  display: none;
  align-items: center;
  justify-content: center;
  width: 20px;
  position: relative;
  top: -2px;
  margin-left: 0.5rem;
  font-size: 12px;

  &.active {
    display: flex;
  }
  
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 18px;
    background: url('../svg/heart-fill.svg') center center no-repeat;
    content: '';
    transform: scale(1.2);

    @include media('<md') {
      width: 100%;
      height: 100%;
    }
  }

  @include media('<md') {
    width: 40px;
    height: 40px;
    position: fixed;
    right: 0;
    top: 50%;
    background-color: color(dark);
    padding: 1rem;
    z-index: 1;
  }
}

.region-nav__wishlist-count {
  position: relative;
  color: white;
  z-index: 2;
}
