.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: 1px solid #ccc;
  border-top-width: 0;
  background: #fff;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, .1);

  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: calc(100vh - 100px);
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  @include media('<=lg') {
    position: fixed;
    top: 100px;
    bottom: auto !important;
    border-top-width: 1px;
  }
}

.autocomplete-suggestion {
  position: relative;
  padding: 0 30% 0 .6em;
  line-height: 2.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .9em;
  color: #333;

  a{
    text-decoration: none;
  }

  .suggestion-type{
    position: absolute;
    right: .6em;
    color: color(text);
  }
}

.autocomplete-suggestion b {
  font-weight: bold;
  color: #fff;
  background: color(primary);
}

.autocomplete-suggestion.selected {
  background: #f0f0f0;
}
