
// Tags list

.tags-list {
  margin-top: 5px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  a {
    padding: 5px;
    border: 1px solid color(primary);
    border-radius: 3px;
    font-size: 14px;
    text-decoration: none;

    #{$all-hovers} {
      text-decoration: underline;
    }
  }

}
