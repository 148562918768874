.mediabox {
  position: relative;
}

.mediabox__images {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  height: 0;
  border: 1px solid color(light);
}

.mediabox__images-item {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: center no-repeat;
  background-size: cover;
  border-radius: 3px;
  transition: opacity 0.3s;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;

    //@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    //  flex: 1 0 100%;
    //}
  }
}

.mediabox__images-item.active {
  opacity: 1;
}

.mediabox__images-left,
.mediabox__images-right {
  cursor: pointer;
  width: 38px;
  color: white;
  text-shadow: 0 0 5px black;
  font-size: 50px;
  height: 100%;
  line-height: 44px;
}

.mediabox__images-right {
  left: 100%;
  margin-left: -38px;
  position: absolute;
}

.mediabox__images-left {
  padding-left: 10px;
}

.mediabox__images-left:before,
.mediabox__images-right:before {
  margin-top: -25px;
  top: 50%;
  height: 50px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.mediabox__images-left:before {
  content: "\00AB";
}

.mediabox__images-right:before {
  content: "\00BB";
}

.mediabox__controller {
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  margin-bottom: 3rem;
}

.mediabox-control__frame {
  width: calc(100% - 2.5rem);
  max-width: calc(100% - 2.5rem);
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin-left: 1.25rem;
  font-size: 0;

  &.mediabox-control__frame--no-nav {
    max-width: none;
    width: 100%;
    margin: 0;
  }
}

.mediabox__controller-image-wrapper {
  border: 1px solid color(grey);
  width: 80px;
  height: 60px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mediabox__controller-item {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 60px;
  margin: 0;
  padding: 0 12px 0 0;
  font-size: 0;
  line-height: 2;
  flex-shrink: 0;
  overflow: hidden;

  img {
    max-height: 100%;
    max-width: 100%;
  }

  &:last-child {
    padding: 0;
  }

  &.active {
    .mediabox__controller-image-wrapper {
      border-color: color(primary);
    }
  }
}

.mediabox__controller-item,
.mediabox__images-item {
  &.video {
    cursor: pointer;

    iframe {
      width: 100%;
      height: 100%;
      pointer-events: auto;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 86%;
      height: 100%;
      background: url('../svg/play-btn.svg') center center no-repeat;
      background-size: 30px 30px;
      z-index: 3;
    }

    &.hide-thumbnail {
      pointer-events: none;

      &:after, &:before {
        content: none;
        pointer-events: none;
      }
    }
  }
}

.mediabox__images-item.video {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1;
  }

  > * {
    display: block;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    z-index: 2;
  }
}

.mediabox__images-item {
  &.active {
    z-index: 2;

    &:after {
      background-size: 120px 120px;
      width: calc(100% - (38px * 2));
      left: 38px;
    }
  }
}

.media-box__control-nav {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    cursor: not-allowed;

    .chevron.orange {
      stroke: color(grey) !important;
    }
  }
}

.media-box__control-nav--left {
  left: 0;
}

.media-box__control-nav--right {
  right: 0;
}

.mediabox-control__list {
  white-space: nowrap;
  //display: block;
  margin: 0;
  display: flex;
  font-size: 0;
}

