// Wishlist container

.pdp-wishlist-container {
    display: flex;
    align-items: center;
    
    @include media('<md') {
        align-items: flex-start;
        flex-direction: column;
    }
    
    input {
        display: none;
    }
  
    .btn {
      display: inline-flex;
      align-items: center;
      margin-right: 1rem;
    }
  
    .btn svg {
      margin-left: 0.5rem;
    }

    &.added {
        svg {
            animation: heartPulse 500ms;
            animation-iteration-count: 1;
        }
    }
}

.pdp-wishlist-container__cta {
    display: none;

    .added & {
        display: inline-block;
    }

    @include media('<md') {
        margin-top: 1rem;
    }
}

.pdp-wishlist-container__label {
    display: flex;
    align-items: center;

    svg {
        position: relative;
        top: -1px;
        margin-left: .5rem !important;
    }

    &:last-child {
        display: none;

        .added & {
            display: flex;
        }
    }

    &:first-child {
        .added & {
            display: none;
        }
    }
}

.pdp-wishlist-container__button {
    position: relative;

    &:hover {
        svg p {
            fill: white;
        }
    }
}

.pdp-wishlist-container__animation-item {
    position: absolute;
    top: 11px;
    right: 37px;
    z-index: 10;
    opacity: 0;
    @include media('>md') {
        &.active {
            animation: heartAnimation 2s;
            animation-iteration-count: 1;
        }
    }
}

@keyframes heartAnimation {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate3d(-3vw, -80vh , 0) scale(0);
        opacity: 0;
    }
}

@keyframes heartPulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.4);
    }
    50% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}