*[class^='col-'] {
  @include grid-column(12);
  overflow: hidden;
  clear: both;
  margin-bottom: 2em;
}

.col-1 *[class^='block-'] {
  width: 100%;
  overflow: hidden;
}

.col-2 *[class^='block-'] {
  width: 100%;

  @include media('>md') {
    @include grid-column(6);
    margin-left: 0;
    margin-right: 20px;
  }
}

.col-3 *[class^='block-'] {
  width: 100%;

  @include media('>md') {
    @include grid-column(4);
    margin-left: 0;
    margin-right: 20px;
  }
}
