.homepage__sub-footer {
@include media('<=lg') {
  margin-top: 40px;
  margin-bottom: 40px;
}
  @include media('>lg') {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
