.splash-content {
  position: relative;
  z-index: 4;
  color: color(white);

  a {
    color: white;
  }

  h1 {
    line-height: 1;
    font-size: 2em;
    color: white;

    @include media('>md') {
      font-size: 2.5em;
    }

    @include media('>md') {
      font-size: 3.75em;
    }
  }

  p a {
    font-weight: bold;
  }

  @include media('>md') {
    p{
      font-size: 1.25em;
    }
  }
}

.splash-home-intro-content {
  margin-top: $spacing-base;
  max-width: 486px;

  @include media('>md') {
    margin-top: $spacing-large*1.8
  }
}
