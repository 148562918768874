.parts-list {
  td {
    vertical-align: middle;
    padding: 0;
  }

  @include media('>lg') {
    th:first-child,
    td:first-child {
      width: 10vw;
    }

    th:last-child,
    td:last-child {
      width: 10vw;
    }
  }

  .product-item__img-wrapper {
    width: 100px;
    height: 100px;
  }
}
