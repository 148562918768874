/* ----------------------------------------------------------- */
/* == tingle v0.13.2 */
/* ----------------------------------------------------------- */
.tingle-modal * {
  box-sizing: border-box;
}

.tingle-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, .8);
  opacity: 0;
  cursor: pointer;
  transition: transform .2s ease;
}

.modal--inline .tingle-modal-box {
  overflow-y: auto;
}

.inline-modal__modal-content{
  padding: $spacing-base;
}

/* confirm and alerts
-------------------------------------------------------------- */

.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

/* modal
-------------------------------------------------------------- */

.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
  display: none;
}

.tingle-modal__close {
  position: fixed;
  top: 10px;
  right: 28px;
  z-index: 1000;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #f0f0f0;
  font-size: 4rem;
  font-family: monospace;
  line-height: 1;
  cursor: pointer;
  transition: color .3s ease;

  &:focus,
  &:hover {
    background-color: transparent;
  }

  &:after {
    display: none;
  }
}

.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 1200px;
  border-radius: 4px;
  background: #fff;
  opacity: 1;
  cursor: auto;
  //transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);
  //transform: scale(1) translateZ(0);
  backface-visibility: hidden;
  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;

  @media screen and (min-width: 1400px) {
    max-height: 90vh;
  }
}

.tingle-modal-box__content {
  padding: 0;
}

.tingle-modal-box__footer {
  padding: 1.5rem 2rem;
  width: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f5f5f5;
  cursor: auto;
}

.tingle-modal-box__footer::after {
  display: table;
  clear: both;
  content: "";
}

.tingle-modal-box__footer--sticky {
  position: fixed;
  bottom: -200px; /* TODO : find a better way */
  z-index: 10001;
  opacity: 1;
  transition: bottom .3s ease-in-out .3s;
}

/* state
-------------------------------------------------------------- */

.tingle-enabled {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-enabled .tingle-content-wrapper {
  filter: blur(8px);
}

.tingle-modal--visible {
  visibility: visible;
  opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
  //transform: scale(1) translateZ(0);
  backface-visibility: hidden;
}

.tingle-modal--overflow {
  overflow-y: scroll;
  padding-top: 8vh;
}

/* btn
-------------------------------------------------------------- */

.tingle-btn {
  display: inline-block;
  margin: 0 .5rem;
  padding: 1rem 2rem;
  border: none;
  background-color: grey;
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  cursor: pointer;
  transition: background-color .4s ease;
}

.tingle-btn--primary {
  background-color: #3498db;
}

.tingle-btn--danger {
  background-color: #e74c3c;
}

.tingle-btn--default {
  background-color: #34495e;
}

.tingle-btn--pull-left {
  float: left;
}

.tingle-btn--pull-right {
  float: right;
}

/* responsive
-------------------------------------------------------------- */

@media (max-width: 540px) {
  .tingle-modal {
    top: 0px;
    display: block;
    padding-top: 60px;
    width: 100%;
  }

  .tingle-modal-box {
    width: auto;
    border-radius: 0;
  }

  .tingle-modal-box__content {
    overflow-y: scroll;
  }

  .tingle-modal--noClose {
    top: 0;
  }

  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }

  .tingle-modal-box__footer .tingle-btn {
    display: block;
    float: none;
    margin-bottom: 1rem;
    width: 100%;
  }

  .tingle-modal__close {
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 60px;
    border: none;
    background-color: #2c3e50;
    box-shadow: none;
    color: #fff;
    line-height: 55px;
  }

  .tingle-modal__closeLabel {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }

  .tingle-modal__closeIcon {
    display: inline-block;
    margin-right: .5rem;
    vertical-align: middle;
    font-size: 4rem;
  }
}

@supports (backdrop-filter: blur(12px)) {
  .tingle-modal {
    backdrop-filter: blur(20px);
  }

  @media (max-width: 540px) {
    .tingle-modal {
      backdrop-filter: blur(8px);
    }
  }

  .tingle-enabled .tingle-content-wrapper {
    filter: none;
  }
}
