.layout--two-col{
  margin: 50px 0;

  &.bg__white {
    background-color: white;
  }

  .collapse {
    overflow: hidden;
  }
}

.layout--one-col {
  margin: 50px 0;
}

.column-main {
  @include grid-column(12);

  @include media('>lg') {
    @include grid-column(9);
    @include grid-shift(.5);
  }
}

.column-sidebar {
  @include grid-column(12);

  @include media('>lg') {
    @include grid-column(2.5);
  }
}
