.content-page--dealer-finder {
  .inner {
    margin-left: 0;
    width: 100%;

    // Marker Cluster styling
    .cluster span {
      display: block;
      margin-top: 8px;
    }
  }
}
