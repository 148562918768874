.search-form {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  z-index: -1;
  padding: $spacing-tiny;
  background: color(dark);

  fieldset {
    position: relative;
  }

  &.is--active {
    @include media('<lg') {
      top: 50px;
    }
  }

  @include media('>lg') {
    position: relative;
    display: inline-block;
    width: 380px;
    padding: 0;
    z-index: 1;
    margin-right: 35px;
    //transition: width 0.25s;

    &.full-width {
      width: 538px;
      float: right;

      .search-form__input {
        max-width: none;
      }

      // + .find-dealer-link {
      //   visibility: hidden;
      // }
    }
  }
}

.search-form__input {
  @include media('<=lg') {
    width: 100%;
    max-width: 100%;
  }
  @include media('>lg') {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

input[type="submit"].search-form__submit {
  appearance: none;
  background-image: url('../svg/search-btn-white.svg');
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  width: 50px;
  height: 100%;
  border: none;
  font-size: 0;
  text-indent: -9999em;
  box-shadow: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: color(primary);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: #fc6805;
  }
}


