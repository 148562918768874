.landing-fixed-nav {
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 50px;

  @include media('>lg') {
    display: block;
    position: absolute;
    bottom: 10px;
    right: -20px;
  }
}

.landing-fixed-nav__item {
  margin-top: 15px;
}

.landing-fixed-nav__link {
  width: 40px;
  height: 40px;
  background: color(primary);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transition: transform 0.2s ease;
  }

  &:hover,
  &:focus {
    &.landing-fixed-nav__link--up {
      svg {
        transform: translateY(-5px);
      }
    }

    &.landing-fixed-nav__link--down {
      svg {
        transform: translateY(5px);
      }
    }
  }
}


.landing-fixed-nav__link--down {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: disabled;
  }
}

@include media('<md') {
  .landing-fixed-nav__link {
    width: 30px;
    height: 30px;

    svg {
      width: 10px;
      height: 6px;
    }
  }
}

@include media('<lg') {
  .fixed-menu__wrapper.show-menu {
    right: 0;
  }
}
