.category-list,
.subcategory-list {
  @include list-clean();
}

.category-list__link {
  // display: none;
  
  .category-sidebar__list & {
    display: none;
  }

  .shown & {
    display: block;
  }

  &.active {
    display: block;
  }
}

.category-list__link,
.subcategory-list__link {
  color: color(text);
  font-size: 1.0625em;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;

  #{$all-hovers} {
    text-decoration: none;
    border-bottom: 1px solid color(grey);
  }

  .active > & {
    font-weight: bold;
    color: color(primary);
  }
}

.subcategory-list {
  padding-left: 1.25rem;
}

.category-list {
  margin-top: 0.625rem;

  .shop__product-filter & {
    border-left: 0;
  }
}

.category-list__item {
  padding-left: 1.25rem;
  margin-bottom: .5em;

  .shop__product-filter & {
    padding-left: 10px;
  }

  &.active {
    position: relative;
  }

  &:not(.active) > .subcategory-list {
    display: none;
  }
}

ul.toggled {
  li.hidden {
    display: block !important;
  }
}

.category-sidebar__list {
  &,
  .subcategory-list {
    padding-left: .75em;
  }
  > li {
    margin-bottom: .5rem;
  }

  .subcategory-list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease;

    li{
      max-height: 0;
    }

    &.active,
    &.shown {
      &{
        max-height: 1000px;
        transition: max-height 0.2s ease;
      }
    }
  }

  .category-list__link.active {
    font-weight: bold;
  }

  .subcategory-list.active{
    > li{
      max-height: 1000px;
    }
  }
}

.filters--list {
  .category-list {
    .category-list__item {
      padding-left: 0;
    }
  }
}
