// Sub item simple navigation
.nav-main__sub-item {

  @include media('<lg') {
    display: none;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @include media('>lg') {
    background-color: #28292A;
    border: 1px solid #515255;
    position: absolute;
    width: auto;
    min-width: 250px;
    transition: opacity 400ms cubic-bezier(0.75, -0.02, 0.2, 0.97), transform 400ms cubic-bezier(0.75, -0.02, 0.2, 0.97), visibility 400ms cubic-bezier(0.75, -0.02, 0.2, 0.97);
    transform: translate(0, 0px);
    visibility: hidden;
    opacity: 0;
  }

}

.nav-main__item {
  &:not(.nav-main__item-products) {
    position: relative;
  }

  &.has-sub {
    @include media('>lg') {
      &:after {
        content: '';
        position: absolute;
        bottom: -7px;
        margin-left: -7px;
        left: 50%;
        width: 14px;
        height: 14px;
        transform: rotate(45deg);
        z-index: 210;
        background-color: #28292A;
        border: 1px solid #515255;
        border-bottom: 0;
        border-right: 0;
        transition: opacity 0s;
        opacity: 0;
        transition: opacity .4s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      }

      &:hover {
        &:after {
          opacity: 1;
          transition: opacity .4s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        }
      }
    }
  }
}

.nav-main__item-toggle-button {
  top: 20px;
  right: 20px;
  position: absolute;
  z-index: 2;
  width: 8px;
  height: 16px;

  &:before {
    transition: transform ease .1s;
    transform: rotate(90deg);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    display: inline-block;
    width: 6px;
    height: 100%;
    background: url('../svg/chevron-white.svg') center center no-repeat;
    background-size: 6px 14px;
  }

  &.is--active {
    &:before {
      transform: rotate(-90deg);
    }
  }

  @include media('>lg') {
    display: none;
  }
}

.nav-main__item-toggle-button.is--active + .nav-main__sub-item{
  display: block;
}

.nav-main__item {
  &:hover,
  &:focus {
    .nav-main__sub-item {
      @include media('>lg') {
        visibility: visible;
        opacity: 1;
        transform: translate(0, 15px);
      }
    }
  }
}

.nav-main-sub-list {
  list-style: none;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
}

.nav-main-sub-list__item {
  @include chevron-list();
  color: color(white);
  padding-left: 1em;

  &:before {
    background-position: center 10px;
  }

  @include media('<=lg') {
    //font-size: $font-size-smaller;
    line-height: 2.75;

    &:before {
      background-position: center 18px;
    }
  }

  @include media('>lg') {
    font-size: $font-size-small;
    line-height: 2;
    white-space: nowrap;
  }

  a {
    color: color(white);
    text-decoration: none;

    #{$all-hovers} {
      color: color(text);
      text-decoration: none;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }
}
