.splash-video {
  video {
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    min-width: 100.5%;
    min-height: 100.5%;
    width: auto;
    height: auto;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    transform: skewX(18deg);
    transform-origin: right top;
    display: block;
    background: rgba(0, 0, 0, .6);
    position: absolute;
    left: calc(-50%  - 80px);
    top: 0;
    z-index: 3;
  }
}
