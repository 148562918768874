.chevron {
  stroke: color(text);
  stroke-width: 2px;

  &.right {
    transform: rotate(0deg);
  }
  &.up {
    transform: rotate(-90deg);
  }
  &.down {
    transform: rotate(90deg);
  }

  &.left {
    transform: rotate(180deg);
  }

  &.orange {
    stroke: color(primary);
  }

  &.dark {
    stroke: color(dark);
  }

  &.grey {
    stroke: color(grey);
  }
}
