.form__checkbox,
.form__radio,
.js-form-type-radio,
.js-form-type-checkbox {
    margin-bottom: 1em;

    &, a {
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;

        input {
            opacity: 0;
            width: 24px;
            height: 24px;
            margin-right: 15px;
            flex-shrink: 0;
        }

        label {
            color: color(text);
            font-size: $font-size-base;
            font-weight: normal;

            &:before {
                content: '';
                width: 24px;
                height: 24px;
                display: block;
                border: 1px solid color(grey);
                background: white;
                position: absolute;
                left: 0;
                top: 0;
                box-shadow: 0 -1px -3px rgba(0, 0, 0, .2);

                .filters--list & {
                    width: 19px;
                    height: 19px;
                    bottom: -5px;
                }

            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                opacity: 0;
                transition: opacity .2s ease;
            }
            margin-bottom: 0;

            .filter__results {
                font-weight: normal;
            }
        }

        input:checked {
            + label {
                font-weight: bold;

                &:before {
                    background: color(tab-bg);
                    border-color: color(primary);
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }
}

.form__checkbox,
.js-form-type-checkbox {
    label {
        &:before {
            border-radius: 3px;
        }
        &:after {
            width: 14px;
            height: 12px;
            background: url(../svg/custom-checkbox.svg);
            background-size: cover;
            left: 5px;
            top: 6px;

            .filters--list & {
                width: 11px;
                height: 8px;
                top: 5px;
                left: 4px;
            }
        }
    }
}

.form__checkbox--link {
    label {
        cursor: pointer;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    input:checked + label {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.form__radio,
.js-form-type-radio {
    margin-bottom: .5rem;

    label {
        &:before {
            border-radius: 50%;
        }

        &:after {
            width: 12px;
            height: 12px;
            left: 6px;
            top: 6px;
            background: color(primary);
            border-radius: 50%;
        }
    }
}

.js-form-type-checkbox,
.js-form-type-radio {
    .error-message {
        position: absolute;
        bottom: 0;
        margin: 0;
    }

    .is-invalid,
    .is-invalid + label {
        padding-bottom: 2em;
    }
}
