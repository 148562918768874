.faqfield-definition-list {
  clear: both;
}

.faqfield-question {
  font-size: 1.25rem;
  border-bottom: 1px solid color(tab-border);
  margin-top: 20px;
  font-weight: bold;
  text-decoration: none;
  color: color(text);
  display: block;
  padding: 0.5rem;
  position: relative;

  #{$all-hovers} {
    cursor: pointer;
  }


  &:before {
    content: '';
    display: inline-block;
    transition: transform 0.25s ease;
    position: absolute;
    right: 15px;
    width: 8px;
    height: 16px;
    top: 40%;
    background: url('/themes/holmatro/assets/svg/chevron-grey.svg');
    transform: rotate(90deg);
  }
  &.open:before {
    transform: rotate(-90deg);
  }
}

.faqfield-answer {
  display: none;
  margin: 0;
  border: 1px solid color(tab-border);
  border-top: none;
  padding: 15px;

  p:last-child {
    margin-bottom: 0;
  }

  &.open {
    display: block;
  }
}

