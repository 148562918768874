.nav-main {
  background: color(dark);
  width: 100%;
}

.nav-main__list {
  display: none;
  @include list-clean();
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
  background-color: color(black);
  max-height: 421px;
  overflow-y: scroll;
  overflow-x: hidden;

  &.is--active {
    display: block;
  }

  li {
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid color(text);

    ul {

      li {
        border-top: 0;
        padding: 0;
        padding-left: 1em;
      }
    }
  }

  @include media('>lg') {
    @include list-clean();
    background-color: color(dark);
    position: static;
    float: right;
    bottom: 0;
    width: auto;
    display: block;
    overflow: hidden;
    max-height: unset;

    @supports (overflow: unset) {
      overflow: unset;
    }

    li {
      width: auto;
      padding: 0;
      border-top: 0;
      padding-left: 1em;
      padding-bottom: .95em;
    }
  }
}

.nav-main__item {
  float: left;
}

.nav-main__item-products .nav-main__item-link {
  @include media('<=lg') {
    display: block;
    width: 100%;
  }

  &:after {
    @include media('>lg') {
      content: '';
      position: absolute;
      bottom: -31px;
      margin-left: -7px;
      left: 50%;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      z-index: 210;
      background-color: #28292A;
      border: 1px solid #515255;
      border-bottom: 0;
      border-right: 0;
      transition: opacity 0s;
      opacity: 0;
    }

    @include media('<=lg') {
      transition: transform .1s ease;
      transform: rotate(90deg);
      position: absolute;
      top: 0;
      left: auto;
      right: 20px;
      bottom: 0;
      content: "";
      display: inline-block;
      width: 6px;
      height: 100%;
      background: url(/themes/holmatro/assets/build/svg/chevron-white.svg) 50% no-repeat;
      background-size: 6px 14px;
      opacity: 100;
      border: none;
    }
  }

  &.is--active {
    @include media('<=lg') {
      &:after {
        transform: rotate(-90deg);
      }
    }
  }
}

.nav-main__item-products:hover {
  .nav-main__item-link {
    z-index: 205;

    @include media('>lg') {
      &:after {
        opacity: 1;
        transition: opacity .4s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      }
    }
  }
}

.nav-main__item-link {
  font-weight: bold;
  font-size: 1.25em;
  color: color(white);
  line-height: 2;
  text-decoration: none;
  padding: 0 18px;
  position: relative;

  #{$all-hovers} {
    color: color(primary);
  }

  .nav-main__item:last-of-type & {
    padding-right: 0;
  }
}
