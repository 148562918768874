.dealerselector__wrapper,
.dealerselector__results-wrapper {
  @include grid-column(12);
}

.dealerselector__wrapper {
  margin-left: 0;
}

.dealerselector__select-row {
  margin-bottom: $spacing-base;
}

[data-dealer-result-target] {
  @include grid-collapse();
}

.dealerselector__dealer {
  @include grid-column(12);
  border: 1px solid color(tab-border);
  padding: $spacing-base;

  @include media('>lg') {
    @include grid-column(6);
    margin-top: 0;

    &:nth-child(odd) {
      clear: left;
    }
  }
}

.dealerselector__dealer-web {
  margin-bottom: 0;
}

.shs-select{
  margin-bottom: .5em;
  padding-right: 2em;
}
