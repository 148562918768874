.control__wrapper {
  @include media('<=lg') {
    display: none;
  }
  @include media('>lg') {
    display: inline-block;
    position: absolute;
    top: 40px;
    right: 10px;
  }
}

button.control__part {
  box-shadow: none;
  appearance: none;
  background: none;
  padding: 0;
  margin: 0 5px;
  display: inline-block;
  float: left;
  width: 10px;
  height: 10px;
  border: 2px solid #4f4f4f;
  border-radius: 50%;

  &:after,
  &:before {
    content: none;
  }

#{$all-hovers} {
  background: none;
  border-radius: 50%;
}

  &.active {
    border-color: color(white);
    background: color(white);
  }
}
