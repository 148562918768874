.pmc__intro {
  background: none;
  position: relative;

  @include media('>lg') {
    @include diagonal-background(color(primary), 90px);
    height: 530px;
    z-index: 0;

    @include media('<uhd') {
      &:before {
        width: calc(40% + 90px);
      }
    }
  }

  .container {
    position: relative;
    @include media('<=lg') {
      background: color(primary);
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @include media('>lg') {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }
}

.pmc-intro__slider {
  overflow: hidden;

  @include media('<=lg') {
    height: 230px;
    position: relative;
  }
  @include media('>lg') {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 70%;
    z-index: -2;
  }

  img {
    position: absolute;
    transition: opacity 1s ease;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
}

.pmc-intro__text {
  h1, h2, h3, p, span, a {
    color: color(white);
  }

  h2 {
    @extend h1;
  }

  @include media('>lg') {
    max-width: 330px;

    p {
      font-size: $font-size-large;
    }
  }
}



