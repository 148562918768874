.heading-group {
  width: 100%;
  overflow: hidden;
  padding-bottom: .75em;
  margin-bottom: $spacing-small * 1.5;
  border-bottom: 1px solid color(light);

  &.no-gap {
    margin-left: 0;
    width: 100% !important;
  }

  .btn-modal {
    display: none !important;

    @media screen and (min-width: 700px) {
      display: block !important;
    }
  }

  h1 {
    float: left;
    font-size: $font-size-h2;
    margin-bottom: 0;
  }

  .btn {
    float: right;
  }
}

.inner {
  @include grid-column(12);
}
