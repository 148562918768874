.container {
  @include container();
}

.collapse {
  @include grid-collapse();
}

.landings-page-section .collapse {
  margin: initial;
}

.collapse--wishlist {
  @include media('<lg') {
    display: flex;
    flex-direction: column;

    .column-main {
      order: 1;
    }

    .column-sidebar {
      order: 2;
    }
  }
}

.comment-box {
  margin-top: 30px;
}
