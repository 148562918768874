/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * This file contains only the print styles from the HTML5 Boilerplate
 * project. See <https://github.com/h5bp/html5-boilerplate> for more
 * information on the main project.
 */

/* ==========================================================================
   Print styles.
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .collapse {
    margin: 0 !important;
    padding: 0 $spacing-small !important;
    width: 100% !important;
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    text-transform: none;
  }

  .toolbar,
  .toolbar-oriented,
  .header,
  .footer,
  .dialog-off-canvas-main-canvas > ul,
  .print-btn,
  .footer-sticky,
  .tab-header__svg,
  .mediabox__images-left,
  .mediabox__images-right,
  .mediabox__controller,
  .btn,
  .nav-sidebar,
  .article__utilities,
  iframe,
  embed,
  object {
    display: none !important;
  }

  .tab__container {
    display: block !important;
  }

  .mediabox__images,
  .tab__wrapper,
  .tab__container {
    border: none;
  }

  .pdp__main,
  .tab__wrapper {
    width: auto;
    float: none;
  }

  .technical-image {
    display: inline-block;

    span {
      display: block;
    }
  }

  .product-item__wrapper {
    border: none;

    .product-item__title {
      padding: 0;
    }
  }

  p:empty {
    padding: 0;
    margin: 0;
  }
}
