@import '../../../../../node_modules/simplelightbox/src/simple-lightbox.scss';

button.sl-close::after {
  display: none;
}

.mediabox img[lightbox-src] {
  cursor: pointer;
}

#mediabox {
  .mediabox__images-item--zoom {
    &::after {
      $icon-size: 100px;
      content: '';
      background-image: url('/themes/holmatro/assets/build/svg/zoom-in.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 60px;
      position: absolute;
      display: block;
      background-color: rgba(black, 0.6);
      top: 50%;
      left: 50%;
      width: $icon-size;
      height: $icon-size;
      border-radius: $icon-size;
      z-index: 10000;
      transform: translate(-50%, -50%);
      transition: opacity .5s ease;
      opacity: 0;
      pointer-events: none;
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}
