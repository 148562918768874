.category__grid {
  overflow: hidden;
  padding-top: 5px;
}
.category-teaser__list {
  display: flex;
  flex-wrap: wrap;
}
.category-teaser {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-items: stretch;
  position: relative;

  @include media('<md') {
    @include grid-column(12);
    @include diagonal-line(grey, 45px);
    justify-content: space-between;

  }

  @include media('>md') {
    @include diagonal-line(grey, 105px);
    margin-bottom: 1.5em;
    padding-left: 20px;
  }

  @include media('>=md', '<=lg') {
    @include grid-column(6);
  }

  @include media('>lg') {
    @include grid-column(4);
  }

  &:before {
    transform: rotate(-18deg) translateY(0);
    left: -15px;
  }

  #{$all-hovers} {
    &:before {
      background: color(primary);
    }
   &:after {
     background-image: url('../svg/chevron-orange.svg');
   }
  }
}

.category-teaser__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-items: stretch;
  width: 100%;

  #{$all-hovers} {
    .category-teaser__title {
      color: color(primary);
    }
  }
}

.category-teaser__img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 10px;

  @include media('<lg') {
    width: 85px;
  }

  @include media('>=lg') {
    width: 145px;
  }
}


.category-teaser__img {
  height: auto;
  display: block;
}

.category-teaser__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  flex: 1;
}

.category-teaser__title {
  font-size: $font-size-base;
  font-family: $font-family-base;
  text-transform: none;
  margin-bottom: .5em;
  letter-spacing: 0;
  color: color(text);

  @include media('<md') {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.category-teaser__description {
  font-size: $font-size-small;
  color: color(text);
  margin: 0;

  @include media('<md') {
    display: none;
  }
}

.category-teaser__chevron {
  @include media('<md') {
    margin-left: 20px;
  }
}

//.category__description-bottom {
//  font-size: 13px;
//}
