.form-item {
  margin-bottom: 1em;

  .description {
    font-size: 90%;
  }

  > *{
    display: block;
  }

  > a{
    display: inline;
  }

  .error{
    border: 1px solid $color-error;
  }

  .success{
    border: 1px solid $color-success;
  }
}
