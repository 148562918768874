.news__list,
.blog__list {
    margin: 0;

    .column-sidebar & {
        list-style: none;
    }
}

.column-sidebar--news,
.column-sidebar--blog {
    @include media('<lg') {
        display: none;
    }
}
