.nav-sidebar__list {
  @include list-clean;
  //height: 0;
  overflow: hidden;
  clear: both;
  margin-bottom: 2em;

  &.is--active {
    height: auto;
  }

  @include media('>lg') {
    height: auto;
  }
}

.nav-sidebar__list-item-link {
  color: color(text);
  font-size: 1.25em;
  font-weight: bold;
  text-decoration: none;
  //border-bottom: 1px dotted color(tab-border);
  display: block;
  padding: $spacing-tiny 0;

  &:hover {
    color: color(primary);
  }

  &.is-active {
    color: color(primary);
    //border-bottom: 1px dotted color(grey);
  }
}

span.nav-sidebar__list-item-link:hover {
  color: color(text);
}

.nav-sidebar__sub-list {
  @include list-clean;
  padding-left: 1em;
}

.nav-sidebar__sub-list-item a {
  color: color(text);
  text-decoration: none;
  padding: $spacing-tiny 0;
  display: block;

  &:hover {
    color: color(primary);
  }

  &.is-active {
    color: color(primary);
    //border-bottom: 1px dotted color(grey);
    font-weight: bold;
  }
}

.nav-sidebar__sub-list-item .nav-sidebar__sub-list-item a.is-active {
  border: none;
}

.page-node-type-blog,
.page-node-type-news {
  .content-page .column-sidebar {
    @include media('<lg') {
      display: none;
    }
    @include media('>=lg') {
      .h3 {
        padding-bottom: 10px;
        display: block;
        //border-bottom: 1px dotted color(tab-border);
      }

      .views-element-container {
        @include list-clean();

        li a {
          color: color(text);
          font-size: 1rem;
          text-decoration: none;
          display: block;

          &:hover {
            color: color(primary);
          }
        }
      }
    }
  }
}
