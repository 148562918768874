.teaser {
  display: block;
  position: relative;
  color: color(text);
  text-decoration: none;
  transition: box-shadow ease 0.1s;

  #{$all-hovers} {
    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.10);
  }
}

.teaser--highlights {
  background-color: color(white);
  width: 100%;
  height: 100%;

  @include media('<lg') {
    white-space: normal;
  }
}

.teaser__img-wrapper {
  position: relative;
  width: 100%;
  height: 160px;
  @include media('>md') {
    height: 320px;
  }
}

.teaser__img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.teaser__label {
  position: absolute;
  left: 0;
  bottom: 20px;
  padding: 8px 16px 7px;
  background: color(text);
  color: color(white);
  border-radius: 0 3px 3px 0;
  box-shadow: 0 2px 1px 0 rgba(0,0,0,0.10);
  font-weight: bold;
  font-size: $font-size-small;

  &:after {
    content: '\203A';
    font-size: 1rem;
    position: relative;
    padding-left: .3em;
    line-height: 1;
    font-weight: normal;
  }
}

.teaser__title {
  @extend h3;
  margin-bottom: 0.5em;
}

.gm-style-iw {
  p {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .btn {
    padding-right: 20px;

    &:after {
      font-size: inherit;
    }

  }
}

.teaser__description {
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px 20px;
}

.teaser__description-text {
  margin-bottom: 0.5em;
  @include media('<=lg') {
    font-size: $font-size-small;
  }
}
