/**
 * settings.colors
 * Project wide color settings.
 */

// Brand/Base colors
$colors: (
  primary: #f45c00,
  black: #000,
  dark: #232323,
  text: #67686a,
  grey: #b8b9ba,
  light: #ddd,
  lighter: #eaeaea,
  off-white: #f2f2f2,
  white: #fff,
  diagonal: #494949,
  diagonalbg: #28292a,
  tab-bg: #eaebeb,
  tab-border: #ddd
) !default;

// Status colors
$color-error: #fc3030 !default;
$color-success: #74bd00 !default;
$color-warning: #f8d407 !default;
