.pager {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .pager__items {
    @include list-inline();

    justify-content: center;
    align-items: center;
    min-height: 45px;
    position: relative;

    @include media('>sm') {
      display: flex;
    }

    li {
      @include diagonal-line(grey, 12px);
      margin-left: .5rem;

      &:before {
        transform: rotate(-18deg) translateY(5%);
      }

      &:first-child:before {
        content: none;
      }

      a {
        color: color(grey);
        text-decoration: none;
        padding: 0 0 0 .5rem;

        &.active {
          color: color(primary);
        }

        #{$all-hovers} {
          text-decoration: underline;
        }
      }

      &.pager__item--first,
      &.pager__item--last {
        display: none !important;
      }

      &.pager__item--previous,
      &.pager__item--next {
        margin: 0;

        @include media('>md') {
          position: absolute;

          a {
            @include btn();
          }
        }

        @include media('<=md') {
          clear: both;
        }

        &:before {
          content: none;
        }

      }

      &.pager__item--previous {
        left: 0;

        a {
          &:before {
            content: '‹';
            position: relative;
            left: -7px;
          }

          &:after {
            content: none;
          }
        }
        + li {
          @include media('>md') {
            &:before {
              content: none;
            }
          }
        }
      }

      &.pager__item--next {
        right: 0;

        @include media('<=md') {
          margin-left: .5rem;
          @include diagonal-line(grey, 12px);
          &:before {
            transform: rotate(-18deg) translateY(5%);
          }

          &:after {
            content: '\203A';
            position: relative;
            padding-left: .3em;
            line-height: 1;
            color: color(grey);
          }
        }
      }

      &.active {
        font-weight: bold;
      }
    }
  }
}

