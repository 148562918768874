.sticky-header {
	position: fixed;
	overflow: hidden;
	z-index: 1;
}

.sticky-header table {
	background-color: color(white);
}

.sticky-table-for-shifting {
	position: absolute;
	left: 0;
	top: 0;
}

.hidden {
	display: none !important;
}

.border-block-view {
	position: static !important;
	#product-overview-results .g_item {
		@media screen and (min-width: 700px) {
			box-sizing: border-box;

			&:hover {
				padding: 9px 0 4px;
			}
		}
	}
	h2 {
		min-height: 40px;
	}
}

.product-compare__table--content {;
	overflow: auto;
}

.comparison__checkbox {
	@media screen and (max-width: 700px) {
		display: none !important;
	}

	@media screen and (min-width: 700px) {
		float: left;
		margin-top: -40px;

		+ .image {
			padding-top: 25px;
		}

		input {
			position: absolute;
			left: -9999px;
		}

		label {
			margin-top: 3px;
			position: relative;
			width: 100%;
		}

		label:before {
			content: '';
			width: 20px;
			height: 20px;
			border: 1px solid #ccc;
			position: relative;
			border-radius: 3px;
			display: inline-block;
			bottom: -5px;
			margin-right: 10px;
		}

		label:after {
			content: '';
			color: color(primary);
			background: url('../svg/custom-checkbox.svg') center center no-repeat;
			position: absolute;
			top: 11px;
			left: 5px;
			width: 10px;
			height: 8px;
			opacity: 0;
			transform: scale(.6);
			transition: opacity ease .1s, transform ease .1s;
		}

		input:checked,
		input.checked {

			+ label:before {
				background-color: #EAEBEB;
				border-color: color(primary);
			}

			+ label:after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

}

.product-compare,
.border-block-view {
	@media screen and (max-width: 700px) {
		.btn.flat-orange {
			display: none !important;

		}
	}
	@media screen and (min-width: 700px) {
		h2 {
			display: inline-block;
		}

		.btn.flat-orange {
			font-size: 15px;
			padding: 8px 10px;
			line-height: 1;
			display: inline-block;
			margin: 10px 0;
			display: none;
			z-index: 20;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.product-compare {
	margin-bottom: 0;
	~ .g_items.g_noOverflowHidden {
		overflow: visible;
	}
	~ .border-block-view {
		overflow: visible;
		.btn-modal-title-view {
			float: none;
			z-index: 20;
			margin-bottom: 0;
			margin-top: 5px;
			margin-left: 5px;

			+ #product-overview-results {
				padding-top: 5px;
			}
		}
	}
}

.slider-visible {
	.product-compare {
		~ .g_items.g_noOverflowHidden {
			overflow: visible;

			+ .border-block-view {
				overflow: visible;
				position: relative !important;
				z-index: 300;

				.btn-modal-title-view {
					z-index: 20;
					position: absolute;
					top: -40px;
					margin-top: 0;
					margin-left: 5px;

					+ #product-overview-results {
						padding-top: 0;
					}
				}
			}

			&:empty,
			&:blank {
				height: 200px;
				+ .border-block-view {
					.btn-modal-title-view {
						+ #product-overview-results {
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
}


.tingle-modal-box {

	.heading-group {
		margin-left: 0;
		width: 100%;

		h2 {
			margin-bottom: 0;
		}
	}

	@media screen and (max-width: 700px) {
		display: none !important;

    .modal--inline & {
      display: block !important;
    }
	}

	@media screen and (min-width: 700px) {
		z-index: 1150;

		.featherlight-content {
			border-radius: 0;
			width: 90%;
			overflow-y: scroll;
			top: 0;
		}

		h2 {
			margin-top: 0;
			font-size: 26px;
			display: inline-block;

			+ a {
				margin-left: 20px;
				display: inline-block;
				text-decoration: underline;
				position: relative;
				top: -4px;
				color: color(black);
				font-weight: bold;
				font-size: 14px;
			}
		}

		.product-tile {
			display: flex;
			align-items: center;
			padding: 35px 10px 10px 10px;
			position: relative;
			background-color: #fff;
			cursor: auto;

			&:after {
				content: '';
				width: 100%;
				height: 100%;
				z-index: 10;
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				border: 0;
				box-shadow: inset 0 0 0 1px color(light);
				pointer-events: none;
			}

			.product-tile__img {
				width: auto;
				height: 200px;
				max-width: 100%;
				margin: 0 auto;
				object-fit: contain;
				object-position: center;
			}
		}

		.product-compare__image {
			padding: 0 10px 0 0 !important;
		}

		.product-compare__product {
			padding: 0 10px;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		.product-tile__remove {
			position: absolute;
			z-index: 5;
			transform: translateZ(1px);
			top: 5px;
			right: 5px;
			text-align: right;
			margin-top: 5px;
			margin-right: 5px;
			font-size: 13px;
			line-height: 1.154;
			transition: color 0.5s ease;
			color: color(grey);

			strong {
				font-size: 14px;
				line-height: 1;
			}

			&:hover,
			&:focus {
				color: color(primary);
				cursor: pointer;
			}
		}

		.product__title {
			font-size: 17px;
			font-weight: bold;
			border-bottom: 1px solid color(grey);
			margin-bottom: 0;
			margin-top: 10px;
			line-height: 1;
			padding-bottom: 5px;
			position: relative;
			z-index: 10;

			a {
				color: color(primary);
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: none;
					color: color(primary);
				}
			}

			span {
				margin-left: 5px;
			}
		}

		.product__detail-link {
			display: block;
			margin-top: 20px;
		}

		.comparison__checkbox {
			line-height: 1;

			label {
				font-size: 12px;
				line-height: 1;

				@include media('>lg') {
				  font-size: 14px;
				}
			}
		}

		.product-description__inner {
			margin-top: 5px;
			position: relative;
			overflow: hidden;
			min-height: 30px;
			padding-bottom: 1em;
			font-size: 13px;
			line-height: 1.5;

			&:after {
				background-image: linear-gradient(rgba(255, 255, 255, 0), #fff 95%, #fff);
				background-repeat: no-repeat;
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 20px;
			}

			&.open {
				height: auto !important;
				margin-bottom: 0;
				overflow: auto;
				padding-bottom: 0;

				&:after {
					display: none;
				}
			}

			p:first-child {
				margin-top: 0;
				margin-bottom: 10px;
			}
		}

		.product__readmore {
			font-size: 14px;
			text-decoration: underline;

			&:hover,
			&:focus {
				text-decoration: none;
				cursor: pointer;
			}

		}

		h3 {
			font-size: 19px;
			margin-bottom: 0;
		}

		.product-tile__wrapper {
			&:hover,
			&:focus {
				cursor: pointer;
			}
		}

		.product__title {
			&:hover,
			&:focus {

				color: color(primary);
			}
		}
	}

	.modal-wrapper__body {
		padding: 15px 25px 0;
		margin-bottom: 25px;
		overflow-x: hidden;
	}

	.product-compare__image {
		vertical-align: top;
	}

	.modal-wrapper__header {
		top: 0;
		background-color: #fff;
		padding: 25px;
		z-index: 7;

		tbody {
			tr:last-child td {
				position: relative;
			}
		}

		td {
			empty-cells: show;
		}

		.checkbox-item {
			padding-left: 0 !important;
			vertical-align: bottom;
		}

		.product-compare__specifications {
			font-family: NeueHelvetica,Arial,sans-serif;
			color: color(primary);
			text-transform: uppercase;
			font-size: 24px;
			letter-spacing: 1px;
			left: 0;
		}

		&.sticky {
			border-bottom: 1px solid color(primary);

			.product__description {
				display: none;
			}

			.comparison__checkbox {
				margin-top: -45px;
			}

			.product-compare__specifications {
				top: 15px;
			}
		}
	}

	.product-compare__table {
		table-layout: fixed;
		width: 100%;
		font-size: 13px;
		position: relative;
		z-index: 5;
		empty-cells: show;
		margin-bottom: 0;

		tbody {

			tr {
				width: 100%;

              td {
                padding: 0;
				  padding-left: 10px;

                span + span {
                  padding-left: .5rem;
                  &:before {
                    content: '/';
                    display: inline-block;
                    margin-right: .5em;
                  }
                }
              }

				&:nth-child(2n+1) {
					background: #f2f2f2;
				}
			}
		}

		.items {
			padding-bottom: 20px;
		}

		td {
			empty-cells: show;
			font-size: 92.308%;
		}

		&.show-differences {
			tbody {
				tr {
					&.differences {
						background: #FFDDC8;
					}
				}
			}
		}

		td,
		th {
			text-align: left;
			width: 25%;
			line-height: 2.2;
		}

		th {
			color: color(primary);
			font-size: 92.308%;
			padding-left: 10px;
			padding-top: 0;
			padding-bottom: 0;
		}

		td {
			&:first-child {
				padding-left: 10px;
			}
		}
	}

	.product-compare__header {
		th {
			line-height: 1 !important;
			padding: 0;
			padding-bottom: 10px;
			padding-top: 10px;
		}

		.items tr {
			background: none !important;
		}

		td {
			padding-left: 10px !important;
			padding-right: 0 !important;
		}
	}

	.product-compare__specifications {
		font-size: 19px;
		line-height: 1 !important;
	}

}

.modal-wrapper__body table td span {
	width: 50%;
	display: inline-block;
}

.js-modal-product-comparison.sticky {
	position: fixed;
	right: 0;
	bottom: -33px;
	bottom: -33px;
	display: none;
	border-bottom-right-radius: 0;
	z-index: 1149;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0;
	margin: 0 !important;
	transition: bottom ease 0.5s;

	&.shown {
		display: inline-block;
		bottom: 0;
	}
}

.g_item.product-compare + .g_item.g_item.g_noPadHz {
	margin-bottom: 0;
	padding-bottom: 3px;
	margin-top: 0;

	> p:first-of-type {
		margin-top: 0;
	}

	> .hr {
		margin-bottom: 0;
	}

	+ .g_items.g_noOverflowHidden {
		#slider-filtering {
			padding-top: 0;
			min-height: 25px;
		}
	}
}

.empty-category-header {
		+ .border-block-view {
			.btn-modal-title-view {
				+ #product-overview-results {
					padding-top: 15px;
				}
			}
		}
}

.searchbar-overview {
	pointer-events: auto !important;

	.btn-modal {
		padding: 8px 10px;
		float: none;
		font-size: 15px;

		@media screen and (max-width: 700px) {
			display: none !important;
		}
	}

	#search-form {
		height: auto;
		margin-bottom: 0;
	}
}

.searchbar-searchpage {
	pointer-events: auto !important;
}

.new-product {
	overflow: hidden;
}
.new-product-label {
	position: absolute;
	right: -30px;
	top: 12px;
	display: block;
	background: color(primary);
	color: #fff;
	line-height: 1.25;
	text-align: center;
	width: 100px;
	transform: rotate(45deg);
	pointer-events: none;

	.flexslider & {
		width: 125px;
		top: 15px;
		right: -40px;

		@media screen and (min-width:700px) {
			width: 150px;
			font-size: 24px;
			right: -33px;
			top: 28px;
		}
	}
}
