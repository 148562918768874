.pmc-item-list {
  @include list-inline;
  float: left;
  width: 100%;
  margin-bottom: $spacing-base * 1.25;
  margin-top: $spacing-base * 0.85;
  display: flex;
  justify-content: space-between;


  .pmc-item {

    .sub-pmc {
      margin-top: 30px;
    }

    .views-exposed-form {
      display: none;
    }

    > ul {
      display: none;
    }
  }

  .pmc-item.active {
    .views-exposed-form {
      display: block;
    }

    > ul {
      display: block;
    }
  }

  li {
    position: relative;
    top: 0;
    transition: all ease 0.1s;

    float: left;
    text-align: center;
    margin-bottom: $spacing-small;

    .inner-block {
      border: 1px solid color(light);
      padding: $spacing-small;
      cursor: pointer;
      display: block;
      text-decoration: none;
    }

    @include media('>lg') {
      padding: $spacing-small / 2;
      width: calc(100% / 4);
      margin-bottom: 0;

      .inner-block {
        min-height: 200px;
      }
    }

    @include media('>lg') {
      &:hover {
        cursor: pointer;

        @include media('>lg') {
          top: -5px;
        }

        .inner-block {
          border: 1px solid color(primary);
        }

        img {
          opacity: 1;
        }

        .pmc-name {
          color: color(primary);
        }
      }
    }

    &.active {
      //cursor: pointer;

      @include media('>lg') {
        top: -5px;
      }

      .inner-block {
        border: 1px solid color(primary);
      }

      img {
        opacity: 1;
      }

      .pmc-name {
        color: color(primary);
      }
    }
  }

  .pmc-block-paired {
    display: inline-block;
    padding: 1.5em 1.5em 0 0;
    width: calc(98% / 2);
    background: color(primary);
    min-height: 380px; //340 without h3

    &:hover {
      top: 0;
    }

    @include media('<lg') {
      min-height: 200px;
    }

    h3 {
      color: white;
      text-align: center;
    }

    li {
      width: 100%;
      padding: 0;
      background-color: transparent;

      .inner-block {
        background-color: color(white);
      }

      @include media('>lg') {
        padding: $spacing-small / 2;
        width: calc(100% / 2);
        margin-bottom: 0;
      }

      img {
        opacity: .5;
      }

      &:hover,
      &.active {
        img {
          opacity: 1;
        }
      }
    }
  }

  img {
    display: none !important;
    transition: img ease 0.1s;
    opacity: .5;
    padding: $spacing-small / 2;

    @include media('>lg') {
      display: block !important;
    }
  }

  .pmc-name {
    font-size: 14px;
    display: block;
    color: color(grey);

    @include media('>lg') {
      margin-top: $spacing-small;
    }
  }
}

.dealerfinder {
  float: left;
  position: relative;
  width: 100%;
}

.dealerlist__item {
  position: relative;
}

.dealerlist__servicecenter,
.dealerlist__default-servicecenter {
  position: absolute;
  right: 0;
  top: 80px;
  width: 60px;
  height: 60px;
  opacity: 1;

  img {
    max-width: 60px;
    height: auto;
  }

  .icon {
    width: 54px;
    height: 59px;
    background-size: contain;

    &[class^='icon--partner-level-'] {
      top: 70px;
      width: 60px;
      height: 70px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    + .icon {
      margin-top: 70px;
    }
  }

  .icon--partner-level-1 {
    background-image: url('../../images/partners/partner-1.jpg');
  }

  .icon--partner-level-2 {
    background-image: url('../../images/partners/partner-2.jpg');
  }

  .icon--partner-level-3 {
    background-image: url('../../images/partners/partner-3.jpg');
  }

  .icon--partner-level-4 {
    background-image: url('../../images/partners/partner-4.jpg');
  }

}
