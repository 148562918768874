.textblock--usp-list,
.textblock--about-holmatro {
  @include media('<=lg') {
    @include grid-column(12);

    p, ul, li {
      font-size: $font-size-small;
    }
  }

}

.textblock--about-holmatro {
  @include media('>lg') {
    @include grid-column(7.5);

    h2 {
      margin-bottom: 1.5rem;
    }
  }
}

.textblock--usp-list {
  @include media('>lg') {
    @include grid-column(4);
    @include grid-shift(0.5);
  }

  ul {
    @extend .usp-list;
    li {
      margin-top: 1rem;

      strong {
        margin-top: 0;
      }
    }
  }
}
