@mixin container($mw: $max-width) {
  max-width: $mw;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $mw + 30px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &:after {
    clear: both;
    content: '';
    display: table;
  }
}



