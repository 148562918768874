.nav-main__toggle{
  @include btn-clean;
  position: absolute;
  left:8px;
  top: 8px;
  background: transparent;
  padding: 8px 12px;
  line-height: 1;

  &:after{
    display: none;
  }

  @include media('>lg'){
    display: none;
  }
}
