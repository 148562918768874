.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
  background: rgba(255, 255, 255, 0.7);
  top: 170px;
  left: 0;
  visibility: hidden;
  transition: all 400ms cubic-bezier(0.75, -0.02, 0.2, 0.97);
  opacity: 0;

  &.is--active {
    @include media('>lg') {
      visibility: visible;
      opacity: 1;
    }
  }

  .user-logged-in & {
    display: none !important;
  }
}
