.mobile-nav-wrapper {
  position: absolute;
  display: inline-block;
  width: 30%;
  height: auto;
  top: 5px;
  left: 0;
  z-index: 4;

  @include media('>lg') {
    width: 100%;
    top: 55px;
    right: 0;
    height: auto;
    background: transparent;
    padding-right: $spacing-small;
  }
}

.mobile-contact-wrapper {
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
  left: 0;
  background: color(dark);
  z-index: 4;

  @include media('>lg') {
    display: none;
  }
}

.mobile-contact-wrapper {
  .find-dealer-link--contact {
    border: none;
  }

  @include media('>lg') {
    display: none;
  }
}

.find-dealer-link-mobile {
  font-size: $font-size-small;
  padding: $spacing-tiny $spacing-small;
  margin: 8px;

  @include media('>lg') {
    display: none;
  }
}

.hamburger {
  position: absolute;
  left: 20px;
  top: 16.5px;
  width: 20px;
  height: 17px;

  @include media('>lg') {
    display: none;
  }
}
